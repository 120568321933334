// Entrance
import './index.less'
import {useEffect,useState} from 'react'
import {useHistory} from 'react-router-dom'
import Logo from '@/assets/images/login/TExLogo.svg'
import OrangeCH from '@/assets/images/login/OrangeCH.svg'
import group21 from '@/assets/images/login/group21.svg'
import group23 from '@/assets/images/login/group23.svg'
import api from '../../../api'
export default function Entrance(){
  const histroy = useHistory()
  const [list,setList] = useState([])
  useEffect(()=>{
    requestList()
  },[])
  const requestList = (async()=>{
    const {data,code,msg} = await api.user.getCommitPlatForm();
    setList(data)
  })
  const handleRegister = (e)=>{
    histroy.replace(
      `/entranceregister/?id=${e}`
    )
  }
  return (
    <div className='entrance'>
      <div className="entrance-logo">
        <img src={Logo} alt="" />
      </div>
      <div className="entrance-info">
        <div className="entrance-info_text">
          Which NGO are you signing up for?
        </div>
        <div className="entrance-info_item">
          {list.map((item,index)=><div key={index} className={ `item-card ${item.id == 2 && 'episcopal-hurch'} ${item.id == 3 && 'grassroots'}`} onClick={()=>handleRegister(item.id)}>
            <img src={OrangeCH} alt="woman" />
            <span>
              {item.name}
            </span>
          </div>)}
        </div>
        <div className="entrance-info_login" onClick={()=>histroy.replace('/entrancelogin')}>
          Login
        </div>
      </div>
    </div>
  )
}

