// Preview
// import './index.less';
export default function Preview({props}){
  const handleBack = () => {
    console.log(props)
  }
  return (
    <>
      预览
      <div onClick={()=>handleBack()}>
        返回
      </div>
    </>
  )
}

