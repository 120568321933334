// vegetable
import './index.less'
import {useState,useEffect,useRef,forwardRef} from 'react'
import {useHistory,useLocation,Link} from 'react-router-dom'
import Divider from '@mui/material/Divider';
import qs from 'query-string'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Back from '@/assets/images/shop/back.svg'
import Personal from '@/components/Personal'
import api from '@/api'
import {shiftTime} from '@/utils/shifttime'
import { useTranslation } from 'react-i18next'
export default function Vegetable(){
  const { t } = useTranslation()
  const {search} = useLocation()
  const histroy = useHistory()
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  const [detail,setDetail] = useState({
    uid:'',
    avatar:'',
    banner:'',
    event_id:'',
    username:'',
    image:'',
    title:'',
    event_desc:'',
    create_time:'',
    event_type:'',
    location:'',
    start_time:'',
    end_time:'',
    date:''
  })
  useEffect(()=>{
    handleEventDetail()
  },[])
  const handleEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventDetail({
      event_id:qs.parse(search).id
    })
    setDetail(data)
    console.log(data,code,msg)
  }
  const handleBack = ()=>{
    window.history.go(-1)
  }
  const handleShopping = ()=>{
    console.log(detail.event_type)
    if(detail.event_type == "vegetable"){
      histroy.push(`/shoppingfood/?id=${detail.event_id}`)
    }else{
      histroy.push(`/shoppingshop/?id=${detail.event_id}`)
    }
  }
  const handlePersonal = ()=>{
    personalRef.current.handleOpen(detail)
  }
  return (
    <div className='vegetable'>
      <PersonalDiv ref={personalRef} />
      <div className="vegetable-head">
        <div className="vegetable-head_left" onClick={handleBack}>
          <img src={Back} alt="" />
          <span className='head-left_text'>Back</span>
        </div>
      </div>
      <div className="vegetable-info">
        <Avatar alt={detail?.username} src={api.url + detail.avatar} />
        <div className="vegetable-info_name">
          {detail?.username}
        </div>
      </div>
      <div className="vegetable-main">
        <div className="vegetable-main_title">
          {detail?.title}
        </div>
        <div className="vegetable-main_date">
          {detail?.event_type}
          <Divider orientation="vertical" flexItem />
          星期一/四收割
          <Divider orientation="vertical" flexItem />
          {shiftTime(detail?.create_time)}
        </div>
        <div className="vegetable-main_intro">
          {detail?.event_desc}
        </div>
        <div className="vegetable-main_img">
          {detail.banner && <img src={api.url + detail.banner} alt="" />}
          {detail.image.split(",").map(item=>item && 
          <img key={item} src={api.url + item}/>)}
        </div>
        <div className="vegetable-main_list">
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {t('home.place')}:
            </label>
            <div className="list-item_value">
              {detail?.location}
            </div>
          </div>
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {t('home.activeTime')}:
            </label>
            <div className="list-item_value">
              {detail?.start_time} - {detail?.end_time}
            </div>
          </div>
          <div className="list-item">
            <label htmlFor="" className="list-item_label">
              {t('home.date')}:
            </label>
            <div className="list-item_value">
              {detail?.date}
            </div>
          </div>
        </div>
        <div className="vegetable-main_button">
          {/*  onClick={handleShopping} */}
          {detail.event_type == "vegetable" && <Link to={`/shoppingfood/?id=${detail.event_id}`}>
            <Button variant="contained" color="primary">
              Start Shopping
            </Button>
          </Link>}
          {detail.event_type != "vegetable" && <Link to={`/shoppingshop/?id=${detail.event_id}`}>
            <Button variant="contained" color="primary">
              Start Shopping
            </Button>
          </Link>}
        </div>
      </div>
    </div>
  )
}

