// Preview
import './index.less';
import React ,{useState,useEffect,useImperativeHandle} from 'react'
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import {useLocation,useHistory } from 'react-router-dom'
import api from '@/api'
import IconClose from '@/assets/images/shear/close.svg'
import { useTranslation } from 'react-i18next'
function usePageReturn(open,handleClose) {
    const location = useLocation();
    const histroy = useHistory()
    useEffect(() => {
      const blockBack = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
      return () => {
        if(open){
          handleClose()
          window.history.pushState(null, document.title, location.pathname);
          window.addEventListener('popstate', blockBack, false);
          return 
        }else{
          return 
          // window.history.back();
          // window.onpopstate = null
        }
        
      };
    }, [open]);
  }

const PreviewDrawer =(props,ref)=>{
  const { t } = useTranslation()
  const [state,setState] = useState(false)
  const [details,setDetails] = useState({
    type:'',
    title:'',
    price:'',
    detail:'',
    banner:'',
    image:'',
    location:'',
    start_date:'',
    start_time:'',
    end_time:'',
    tb_c:'',
  })
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setDetails(e)
      setState(true)
    }
  }))
  
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const handleClose = ()=>{
    setState(false)
  }
  // usePageReturn(state,handleClose)
  const handleEdit = () =>{

  }
  const handleGet = ()=>{

  }
  return (
    <>
      <Drawer className='preview-drawer' anchor={'right'} open={state} onClose={handleClose}>
        <div className="drawer-main">
          <div className="preview-head">
            <div className="preview-head_avatar">
              <Avatar src={api.url + redusxUser.image} alt={redusxUser.first_name} />
              <div className="avatar-name">
                {redusxUser.username}
              </div>
            </div>
            <div className="preview-head_close" onClick={handleClose}>
              <img src={IconClose} alt="" />
            </div>
          </div>
          <div className="preview-title">
            {details?.title}
          </div>
          <div className="preview-tag">
            <span>{details?.type}</span>
            <Divider orientation="vertical" flexItem />
            <span>{details?.price} {t('my.hm')}</span>
            <Divider orientation="vertical" flexItem />
            <span>刚刚</span>
          </div>
          <div className="preview-content">
            {details?.detail}
          </div>
          <div className="preview-img">
            {details.banner && <img src={api.url + details.banner} alt="" />}
            {details?.image.split(",").map(item=>item && 
            <img key={item} src={api.url + item}/>)}
          </div>
          <div className="preview-list">
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.locations')}:
              </label>
              <div className="list-card_value">
                {details?.location}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('home.date')}:
              </label>
              <div className="list-card_value">
                {details?.start_date}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.time')}:
              </label>
              <div className="list-card_value">
                {details?.start_time} - {details?.end_time}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.selectTime')}:
              </label>
              <div className="list-card_value">
                {details?.tb_c}
              </div>
            </div>
          </div>
          {/* <div className="preview-button">
            <Button variant="outlined" color="primary" onClick={handleEdit}>
              Edit
            </Button>
            <Button variant="contained" color="primary" onClick={handleGet}>
              Get Approval
            </Button>
          </div> */}
        </div>
      </Drawer>
    </>
  )
}
export default PreviewDrawer

