// login
import './index.less';
import React,{useState,useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {useHistory} from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import {setToken,setCipher,getCipher} from '@/utils/auth'
import Logo from '@/assets/images/login/TExLogo.svg';
import api from '@/api'
import {loginAction,tipsReveal,UserAction,ShopAction,BalanceAction} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { stat } from 'fs-extra';
const passwordRE =/^(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
import countryObj from '@/utils/countryCodeAndPhoneCode.json'
export default function Login(){
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const histroy = useHistory()
  const [status,setStatus] = useState(true)
  const [passwordText,setPasswordText] = useState('至少8個字符，1個數字和1個大寫字母')
  const [state, setState] = useState({
    phone_number:'',
    password:'',
    phone_code:'86',
    bool:false,
  });
  useEffect(()=>{
    if(getCipher()){
      console.log(JSON.parse(getCipher()))
      setState(JSON.parse(getCipher()))
    }
  },[])
  const handleSumit = async()=>{
    if(!state.phone_number)return setStatus(false)
    if(!state.password)return setStatus(false)
    if(!passwordRE.test(state.password)){
      setPasswordText('至少8個字符，1個數字和1個大寫字母')
      setStatus(false)
      return
    }
    const {data,code,msg} = await dispatch(loginAction(state))
    if(code != 0){
      dispatch(tipsReveal({message:msg,type:'error'}))
      return 
    } 
    dispatch(UserAction())
    dispatch(BalanceAction())
    dispatch(ShopAction())
    histroy.replace('/home')
  }
  return (
    <div className='login'>
      <div className="login-logo">
        <img src={Logo} alt="" />
      </div>
      <div className="login-form">
        <form action="">
          <TextField
            label={t('login.mobilePhoneNumber')}
            name='phone_number'
            multiline
            variant="outlined"
            defaultValue={state.phone_number}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            // error={state.phone?true:false}
            helperText={state.phone_number || status?'':t('login.pleaseEnter') + t('login.mobilePhoneNumber')}
            InputProps={{
              startAdornment:
              <FormControl className="control-phone_cpde">
                <Select
                  name='phone_code'
                  value={state.phone_code}
                  defaultValue={state.phone_code}
                  onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
                >
                  {countryObj.map((item,index)=>
                    <MenuItem key={index} value={item.phone_code}>
                      +{item.phone_code}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            }}
          />
          <TextField
            label={t('login.cipher')}
            name="password"
            type="password"
            variant="outlined"
            defaultValue={state.password}
            autoComplete="current-password"
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            helperText={state.password && passwordRE.test(state.password)|| status?'':passwordText}
          />
          
          {/* t('login.pleaseEnter') + t('login.cipher') */}
          <div className="login-form_checkbox">
            <FormControlLabel
              control={<Checkbox checked={state.bool}  name="bool" color="primary" onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.checked })}}/>}
              label={t('login.rememberThePassword')}
            />
            <span className="login-form_checkbox-forgot" onClick={()=>histroy.replace('/entranceforgot')}>
              forgot password
              {/* Sign in */}
            </span>
          </div>
          <Button variant="contained" color="primary" onClick={handleSumit}>
            {t('login.logIn')}
          </Button>
        </form>
      </div>
    </div>
  )
}

