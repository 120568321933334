// vegetable
import './index.less'
import {useState,useEffect,useRef,forwardRef} from 'react'
import {useHistory,useLocation} from 'react-router-dom'
import qs from 'query-string'
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import Back from '@/assets/images/shop/back.svg'
import IconClose from '@/assets/images/shear/close.svg'
import Personal from '@/components/Personal'
import api from '@/api'
import {shiftTime} from '@/utils/shifttime'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
export default function Vegetable(){
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {search} = useLocation()
  console.log(qs.parse(search).id)
  const histroy = useHistory()
  const PersonalDiv = forwardRef(Personal)
  const personalRef = useRef(null)
  const [userList,setUserList] = useState([])
  const [userData,setUserData] = useState({
    event_id:'',
    uid:''
  })
  const [details,setDetails] = useState({
    type:'',
    uid:'',
    avatar:'',
    banner:'',
    event_id:'',
    username:'',
    image:'',
    title:'',
    event_desc:'',
    create_time:'',
    event_type:'',
    location:'',
    start_time:'',
    end_time:'',
    date:'',
    start_date:'',
    price:'',
    detail:'',
    is_approve:0,
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    handleEventDetail()
  },[])
  const handleEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventActiveDetail({
      event_id:qs.parse(search).id
    })
    setDetails(data)
    if(data.uid == redusxUser.uid){
      var a = await api.shear.GetUserEventUserList({
        event_id:data.event_id,
      })
      if(a.data.length == undefined)return setUserList([])
      setUserList(a.data)
    }
  }
  
  const handleShopping = ()=>{
    console.log(details.event_type)
    if(details.event_type == "vegetable"){
      histroy.push(`/shoppingfood/?id=${details.event_id}`)
    }else{
      histroy.push(`/shoppingshop/?id=${details.event_id}`)
    }
  }
  useEffect(()=>{
    personalRef.current.handleOpen(userData)
  },[userData])
  const handlePersonal = (e)=>{
    if(e.uid != userData.uid)return setUserData(e)
    personalRef.current.handleOpen(e)
    
  }
  const handleClose = ()=>{
    window.history.go(-1)
  }
  const handleEdit = () =>{
    console.log(details)
    histroy.push(`/sharingform/?event_id=${details.event_id}`)
  }
  const handleGet = ()=>{

  }
  const handleSignUp = async()=>{
    const {data,code,msg} = await api.shear.PostUserEventJoin({
      event_id:details.event_id
    })
    if(code != 0)return dispatch(tipsReveal({message:'Operation failure',type:'error'}))
    handleEventDetail()
    dispatch(tipsReveal({message:'Successful registration',type:'success'}))
  }
  // const handleDecline = async ()=>{
  //   console.log(userData.event_id,userData.uid)
  // }
  const handleConfirm = async (e)=>{
    const {data,code,msg} = await api.shear.PostUserEventJoinChoose({
      event_id: userData.event_id, 
      uid: userData.uid, 
      is_choose: e
    })
    if(code!=0)return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(tipsReveal({message:msg,type:'success'}))
    handleEventDetail()
    console.log(data,code,msg)
  }
  return (
    <div className='sharing-details'>
      <PersonalDiv ref={personalRef} setUserData={setUserData}>
        <>
          <Button variant="outlined" color="primary" onClick={()=>handleConfirm(2)}>
            Decline
          </Button>
          <Button variant="contained" color="primary" onClick={()=>handleConfirm(1)}>
            Confirm
          </Button>
        </>
      </PersonalDiv>
      <div className="drawer-main">
          <div className="preview-head">
            <div className="preview-head_avatar">
              <Avatar src={api.url + details?.avatar} alt={details?.username} />
              <div className="avatar-name">
                {details.username}
              </div>
            </div>
            <div className="preview-head_close" onClick={handleClose}>
              <img src={IconClose} alt="" />
            </div>
          </div>
          <div className="preview-title">
            {details?.title}
          </div>
          <div className="preview-tag">
            <span>{details?.type}</span>
            <div className="preview-tag-round"></div>
            <span>{details?.price} {t('my.hm')}</span>
            <div className="preview-tag-round"></div>
            <span>{shiftTime(details?.date || details?.start_date)}</span>
          </div>
          {redusxUser.uid == details.uid &&<div className="preview-user">
            {userList.map((item)=><div className="preview-user_item" key={item.uid} onClick={()=>handlePersonal(item)}>
              <div className="preview-user_item-left">
                <Avatar src={api.url + item?.avatar} alt={item.username} />
                <div className="item-left_info">
                  <div className="item-left_info-name">
                    {item.username || 'null'}
                  </div>
                  <div className="item-left_info-des">
                    Asking for Request
                  </div>
                </div>
              </div>
              {/* <div className="preview-user_item-right">
                null
              </div> */}
            </div>)}
          </div>}
          <div className="preview-img">
            {details.banner && <img src={api.url + details.banner} alt="" />}
          </div>
          <div className="preview-content">
            {details?.detail}
          </div>
          <div className="preview-img">
            {details?.image.split(",").map(item=>item && 
            <img key={item} src={api.url + item}/>)}
          </div>
          <div className="preview-list">
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.locations')}:
              </label>
              <div className="list-card_value">
                {details?.location}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('home.date')}:
              </label>
              <div className="list-card_value">
                {details?.start_date}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.time')}:
              </label>
              <div className="list-card_value">
                {details?.start_time} - {details?.end_time}
              </div>
            </div>
            <div className="list-card">
              <label htmlFor="" className="list-card_label">
                {t('sharing.selectTime')}:
              </label>
              <div className="list-card_value">
                {details?.start_date}
              </div>
            </div>
          </div>
          <div className="preview-button">
            {redusxUser.uid == details.uid && 
            <><Button variant="outlined" color="primary" onClick={handleEdit}>
              Edit
            </Button>
            {details.is_approve != 1 && <Button variant="contained" color="primary" onClick={handleGet}>
              Get Approval
            </Button>}
            </>}
            {redusxUser.uid != details.uid && 
              <>
                {details.is_choose === "" && <Button variant="contained" color="primary" onClick={handleSignUp}>
                  Sign Up
                </Button>}
                {details.is_choose === 0 && <Button variant="contained" color="primary">
                  Already registered
                </Button>}
              </>
            }
          </div>
        </div>
    </div>
  )
}

