import React,{ Component,Suspense } from 'react'
// import { BrowserRouter,HashRouter} from 'react-router-dom'
import { HashRouter as Router,Route,Redirect,Switch } from 'react-router-dom'
import DynamicRouter from './router'
// import Loding from '@/components/Loding'
// import RouterAuth from './RouterAuth'
import {getToken} from '@/utils/auth'
import Home from '@/views/home'
import ShoppingShop from "@/views/shopping/shop"
import ShoppingCart from "@/views/shopping/cart"
import ShoppingOrder from "@/views/shopping/order"
import ShoppingVegetable from "@/views/shopping/vegetable"
import ShoppingFood from "@/views/shopping/food"
import EntranceMain from "@/views/entrance/main"
import EntranceLogin from "@/views/entrance/login"
import EntranceRegister from "@/views/entrance/register"
import EntranceCode from "@/views/entrance/code"
import EntranceForgot from "@/views/entrance/forgot"
import SharingHome from "@/views/sharing/home"
import SharingForm from "@/views/sharing/form"
import SharingPreview from "@/views/sharing/preview"
import SharingDetails from "@/views/sharing/details"
import SharingPayment from "@/views/sharing/payment"
import SharingMark from "@/views/sharing/mark"
import UserNew from "@/views/user/new"
import UserMy from "@/views/user/my"
import UserContact from "@/views/user/contact"
import UserTransition from "@/views/user/transition"
import UserQrCode from "@/views/user/qrCode"
import UserCheck from "@/views/user/check"
import UserCollect from "@/views/user/collect"
export default class indexRouter extends Component {
  props
  render() {
    return (
      <>
      {/* <Suspense fallback={<Loding />}> */}
      {/* //   <HashRouter>
      //     <DynamicRouter />
      //     {this.props?.children}
      //   </HashRouter> */}
      <Router>
        <Switch>
          {/* <Redirect from="/" to="/entrancemain" exact /> */}
          <Route path="/entrancemain" component={EntranceMain} />
          <Route path="/entrancelogin" component={EntranceLogin} />
          <Route path="/entranceregister" component={EntranceRegister} />
          <Route path="/entrancecode" component={EntranceCode} />
          <Route path="/entranceforgot" component={EntranceForgot} />
          {/* <Route path="/home" component={Home}/>
          <Route path="/shoppingshop" component={ShoppingShop} />
          <Route path="/shoppingcard" component={ShoppingCart} />
          <Route path="/shoppingorder" component={ShoppingOrder} />
          <Route path="/shoppingvegetable" component={ShoppingVegetable} />
          <Route path="/shoppingfood" component={ShoppingFood}/>
          <Route path="/usernew" component={UserNew} />
          <Route path="/usermy" component={UserMy} />
          <Route path="/usercontact" component={UserContact} />
          <Route path="/usertransition" component={UserTransition} />
          <Route path="/userqrcode" component={UserQrCode}/>
          <Route path="/usercheck" component={UserCheck} />
          <Route path="/usercollect" component={UserCollect}/>
          <Route path="/sharinghome" component={SharingHome} />
          <Route path="/sharingform" component={SharingForm} />
          <Route path="/sharingpreview" component={SharingPreview} />
          <Route path="/sharingdetails" component={SharingDetails} />
          <Route path="/sharingpayment" component={SharingPayment}/>
          <Route path="/sharingmark" component={SharingMark}/> */}
          {DynamicRouter.map((item, index) => 
            <Route
              key={index}
              path={item.path}
              exact={item.exact}
              // component={item.component}
              render={()=>{
                return getToken() && item.redirct ? <item.component/> :<Redirect to="/entrancemain"></Redirect>
              }}
            />
          )}
          {/* <Route path="/home" component={Home}/> */}
          {/* <Route path="/sharingdetails" component={SharingDetails}/> */}
          {/* <DynamicRouter />  */}
        </Switch>
        {this.props?.children}
      </Router>
      {/* </Suspense> */}
      </>
    )
  }
}

