// personal
import './index.less';
import React ,{useState,useEffect,useImperativeHandle} from 'react'
import api from '@/api'
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconClose from '@/assets/images/shear/close.svg'
import IconSchool from '@/assets/images/user/school.svg'
import IconDistance from '@/assets/images/user/distance.svg'
import IconPets from '@/assets/images/user/pets.svg'
import IconMobile from '@/assets/images/user/mobile.svg'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
const Personal = (props,ref)=>{
  const dispatch = useDispatch()
  const [open,setOpen] = useState(false)
  const [detail,setDetail] = useState()
  const [info,setInfo] = useState({
    image:'',
    avatar:'',
    username:'',
    address:'',
    phone_number:'',
    personal_skills:'',
    edu_level:''
  })
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      if(!e.uid)return
      setOpen(true)
      handleInfoView(e.uid)
    }
  }))
  useEffect(()=>{
  },[detail])
  const handleInfoView = async(uid)=>{
    const {data,code,msg} = await api.shear.PostUserInfoView({
      uid
    })
    setInfo(data)
  }
  const handleClose = ()=>{
    setOpen(false)
  }
  const handleCopy = async (e)=>{
    try {
      const whatsappUrl = `https://wa.me/${e}`;
      window.open(whatsappUrl);
      dispatch(tipsReveal({message:'Successful replication',type:'success'}))
      await navigator.clipboard.writeText(e);
    } catch (err) { 
      dispatch(tipsReveal({message:'Failed to copy',type:'error'}))
    }
  }
  return (
    <Dialog className="personal-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <div className="personal-main">
        <div className="personal-main_close" onClick={handleClose}>
          <img src={IconClose} />
        </div>
        <div className="personal-main_card">
          <div className="card-left">
            <Avatar src={api.url + info?.image} />
            <div className="card-name">
              {info?.username}
            </div>
          </div>
          <div className="card-right">
            <div className="card-list">
              <div className="card-list_item">
                <div className="item-text">
                  {info?.score_data?.score || 0}
                </div>
                <div className="item-des">
                  Rating
                </div>
              </div>
              <div className="card-list_item">
                <div className="item-text">
                  3
                </div>
                <div className="item-des">
                  Months
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-main_info">
          <div className="info-item">
            <div className="info-item_label">
              <img className="info-item_img" src={IconSchool} alt="" />
              <div className="info-item_text">
                {/* Studied in abc where-ever school */}
                {info?.edu_level}
              </div>
            </div>
          </div>
          <div className="info-item">
            <div className="info-item_label">
              <img className="info-item_img" src={IconDistance} alt="" />
              <div className="info-item_text">
                {info?.address}
              </div>
            </div>
          </div>
          {/* <div className="info-item">
            <div className="info-item_label">
              <img className="info-item_img" src={IconPets} alt="" />
              <div className="info-item_text">
                {info?.personal_skills}
              </div>
            </div>
          </div> */}
          <div className="info-item">
            <div className="info-item_label">
              <img className="info-item_img" src={IconMobile} alt="" />
              <div className="info-item_text">
                {info?.phone_number}
              </div>
            </div>
            <div className="info-item_copy" onClick={()=>handleCopy(info?.phone_number)}>
              Copy number
            </div>
          </div>
        </div>
        <div className="personal-main_intro">
          {info?.personal_skills}
        </div>
        <div className="personal-mian_read">
          Read more
        </div>
        <div className="personal-mian_img">
          {/* <img src="https://img.alicdn.com/imgextra/i4/3944105410/O1CN01a2rnqW1pppeJa5b8A_!!3944105410-2-alimamacc.png_360x360xzq75.jpg_.webp" alt="" />
          <img src="https://img.alicdn.com/imgextra/i4/3944105410/O1CN01a2rnqW1pppeJa5b8A_!!3944105410-2-alimamacc.png_360x360xzq75.jpg_.webp" alt="" />
          <img src="https://img.alicdn.com/imgextra/i4/3944105410/O1CN01a2rnqW1pppeJa5b8A_!!3944105410-2-alimamacc.png_360x360xzq75.jpg_.webp" alt="" />
          <img src="https://img.alicdn.com/imgextra/i4/3944105410/O1CN01a2rnqW1pppeJa5b8A_!!3944105410-2-alimamacc.png_360x360xzq75.jpg_.webp" alt="" />
          <img src="https://img.alicdn.com/imgextra/i4/3944105410/O1CN01a2rnqW1pppeJa5b8A_!!3944105410-2-alimamacc.png_360x360xzq75.jpg_.webp" alt="" /> */}
        </div>
        <div className="personal-main_btn">
          {props.children}
        </div>
      </div>
    </Dialog>
  )
}
export default Personal