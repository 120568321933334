import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import api from '@/api'
import {useLocation,useHistory } from 'react-router-dom'
import {shiftTime} from '@/utils/shifttime'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux';
export default function ShearCard({item,handleService,type}){
  const { t } = useTranslation()
  const histroy = useHistory()
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const handleSumit = ()=>{

  }
  // 开始服务
  const handleCardService = ()=>{
    handleService(item)
  }

  const handleStart = ()=>{

  }
  const handlePayment = (e)=>{
    // /sharing/payment
    histroy.push(`/sharingpayment/?id=${e}`)
  }
  const handleDetail = ()=>{
    histroy.push(`/sharingdetails/?id=${item.event_id}`)
  }
  return (
    <>
      <div className="shear-card">
        <div className="card-main" onClick={handleDetail}>
          {!item.join_uid && <>{item.is_approve == 0 && <div className="shear-card_tips shear-card_mistake">
            waiting for approval
          </div>}
          {item.is_approve == 1 && <div className="shear-card_tips">
            on marketplace waiting for response
          </div>}
          {item.is_approve == 2 && <div className="shear-card_tips shear-card_mistake">
            Fail the audit
          </div>}</>}
          <div className="shear-card_img">
            <img src={api.url + item.banner} alt="" />
          </div>
          <div className="shear-card_info">
            <div className="info-title">
              {item.title}
            </div>
            <div className="info-tag">
              <span>{item.type}</span>
              <Divider orientation="vertical" flexItem />
              <span>{item.price} {t('my.hm')}</span>
              <Divider orientation="vertical" flexItem />
              <span>{shiftTime(item.create_time)}</span>
            </div>
            <div className="info-intro">
              <span className="info-intro_dec">
                {item.detail}
              </span>
              <span>
                {t('sharing.readMore')}
              </span> 
            </div>
          </div>
        </div>
        <div className="card-button">
          {/* {item.is_share == 0 && item.status == 1 && type == 'request' && <Button variant="contained" color="primary">
            等待服务
          </Button>}
          {item.is_share == 0 && item.status == 2 && type == 'request' && <Button variant="contained" color="primary" onClick={()=>handlePayment(item.event_id)}>
            支付费用
          </Button>} */}
          {type == 'shear' && <>
            {
              item.status == 0 && <Button variant="contained" color="primary" onClick={handleStart}>
                {t('sharing.startSharingNow')}
              </Button>
            }
            {
              item.status == 1 && <Button variant="contained" color="primary">
                等待服务
              </Button>
            }
            {
              item.status == 2 && !item.end_time && <Button variant="contained" color="primary">
                正在服务
              </Button>
            }
            {
              !item.bills_id && item.status == 2 && <Button variant="contained" color="primary">
                等待对方支付
              </Button>
            }
            {
              item.bills_id && <Button variant="contained" color="primary">
                交易完成
              </Button>
            }
          </>}
          {type == 'request' && <>
            {
              item.status == 0 && <Button variant="contained" color="primary">
                等待接取
              </Button>
            }
            {
              item.status == 1 && item.bills_id && <Button variant="contained" color="primary" onClick={handleStart}>
                等待服务
              </Button>
            }
            {
              item.status == 2 && !item.bills_id && <Button variant="contained" color="primary" onClick={()=>handlePayment(item.event_id)}>
                支付费用
              </Button>
            }
            {
              item.bills_id && item.status != 0 && <Button variant="contained" color="primary">
                交易完成
              </Button>
            }
          </>
          }
          {type == 'participate' && <>
            {
              item.is_share == 1 && item.status != 2 && <Button variant="contained" color="primary">
                等待服务
              </Button>
            }
            {
              item.is_share == 0 && item.status == 1  && <Button variant="contained" color="primary" onClick={handleCardService}>
                开始服务
              </Button>
            }
            {
              item.status == 2  && item.is_share ==1 && !item.bills_id && <Button variant="contained" color="primary" onClick={()=>handlePayment(item.event_id)}>
                支付费用
              </Button>
            }
            {
              item.status == 2 &&  item.is_share == 0&& !item.bills_id && <Button variant="contained" color="primary">
                等待对方付款
              </Button>
            }
            {
              item.bills_id && item.status != 0 && <Button variant="contained" color="primary">
                交易完成
              </Button>
            }
          </>}
          {type == 'service' && <>
            {
              !item.end_time && <Button variant="contained" color="primary" onClick={handleCardService}>
                开始服务
              </Button>
            }
            {
              item.end_time && !item.bills_id && <Button variant="contained" color="primary">
                等待对方付款
              </Button>
            }
            {
              item.bills_id && <Button variant="contained" color="primary">
                交易完成
              </Button>
            }
          </>}
          
          
        </div>
      </div>
    </>
  )
}