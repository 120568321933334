// shear
import './index.less';
import {useState,useEffect,useRef,useCallback} from 'react'
import {useHistory,useLocation } from 'react-router-dom'
import qs from 'query-string'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next'
import IconAdd from '@/assets/images/shear/add.svg'
import ShearDialog from './components/ShearDialog';
import Participate from './components/Participate'
import Service from './components/Service'
import Shear from './components/Shear'
export default function ShearHome(){
  const { t } = useTranslation()
  const {search} = useLocation()
  const history = useHistory()
  const [value, setValue] = useState(qs.parse(search).active?Number(qs.parse(search).active) : 1);
  const [shearOpen,setShearOpen] = useState(false)
  useEffect(()=>{

  },[])
  const handleChange = (event, newValue) => {
    history.replace(`/sharinghome/?active=${newValue}`)
    setValue(newValue);
  };
  const handleDialogDetails = useCallback((e)=>{
    setShearOpen(e)
  },[])
  return (
    <div className='shear'>
      <div className="shear-title">
        {t('sharing.title')}
      </div>
      <>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Share" value={1} />
          <Tab label="Request" value={0} />
          <Tab label="Participate" value={2} />
          <Tab label="Service" value={3} />
        </Tabs>
        <div className="shear-main">
          {value == 1  && <Shear handleDialogDetails={handleDialogDetails} value={value} />}
          {value == 0  && <Shear handleDialogDetails={handleDialogDetails} value={value} />}
          {value == 2 && <Participate/>}
          {value == 3 && <Service />}
        </div>
        
        {value != 2  && value!= 3 && <div className="shear-btn" onClick={()=>handleDialogDetails(true)}>
          <div className="shear-btn_add">
            <img src={IconAdd} alt="" />
          </div>
        </div>}
      </>
      <ShearDialog value={value} shearOpen={shearOpen} handleDialogDetails={handleDialogDetails} />
    </div>
  )
}

