import './index.less';
import React, { useEffect } from 'react';
import {useHistory,useLocation,Link } from 'react-router-dom'
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Badge from '@mui/material/Badge';
import marketplace from '@/assets/images/navigation/marketplace.svg'
import shear from '@/assets/images/navigation/shear.svg'
import shoppingCart from '@/assets/images/navigation/shopping-cart.svg'
import my from '@/assets/images/navigation/marketplace.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux';
export default function Navigation(){
  const redusxCount = useSelector((state)=>{
    return state.ShoppingCartRd.count
  })
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  useEffect(()=>{
    setValue(location.pathname);
  },[location.pathname])
  const handleChange = (_event, newValue)=>{
    // console.log(_event, newValue)
    history.replace(
      newValue,
       // 加上替换之前路由
    )
    // {replace:true}
    window.scrollTo(0, 0);
  }
  const [value, setValue] = React.useState(location.pathname);
  return (
    <>
    <div className="navigation-height"></div>
    <BottomNavigation
      value={value}
      onChange={(_event, newValue)=>handleChange(_event, newValue)}
      showLabels={true}
    >
      <BottomNavigationAction value="/home" label={t('navigation.marketplace')} icon={<img src={marketplace} alt="" />} />
      <BottomNavigationAction value="/sharinghome" label={t('navigation.shear')} icon={<img src={shear} alt="" />} />
      <BottomNavigationAction value="/shoppingcard" label={t('navigation.shoppingCart')} icon={<Badge badgeContent={redusxCount} color="primary"><img src={shoppingCart} alt="" /></Badge>} />
      <BottomNavigationAction value="/usermy" label={t('navigation.me')} icon={<img src={my} alt="" />} />
    </BottomNavigation>
    </>
  );
}