// shop
import './index.less'
import {useState,useEffect, useCallback,forwardRef,useRef} from 'react'
import { useDispatch } from 'react-redux'
import {useHistory,useLocation} from 'react-router-dom'
import qs from 'query-string'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import {hide,show} from '@/redux/actions'
import AppendDialog from './appendDialog'
import { useInView } from "react-cool-inview";
import api from '@/api'
import stateLayer from '@/assets/images/shop/state-layer.svg'
import { useTranslation } from 'react-i18next'
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Shear(){
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const [value, setValue] = useState('food');
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const [detail,setDetail] = useState({
    title:''
  })
  const AppendDialogDiv = forwardRef(AppendDialog)
  const AppendDialogRef = useRef(null)
  const [formData,setFormData] = useState({
    event_id:qs.parse(search).id,
    product_type:'food',
    page:1,
    limit:10,
  })
 
  useEffect(()=>{
    handleEventDetail()
  },[])
  useEffect(()=>{
    console.log(formData)
    handleShopEvent()
  },[formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
      handleShopEvent()
    },
  });
  const handleEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventDetail({event_id:qs.parse(search).id})
    setDetail(data)
  }
  const handleShopEvent = async ()=>{
    const {data,code,msg,total:count} = await api.home.GetCommonProduct({...formData})
    if(data.length == undefined)return setList([])
    setList([...list,...data])
    setTotal(count)
  }
  
  const handleChange = (event,newValue)=>{
    setList([])
    setFormData({...formData,['product_type']:newValue,['page']: 1})
  }
  const handleAppend = useCallback((e)=>{
    AppendDialogRef.current.handleOpen(e)
  },[])
  return (
    <div className='shop'>
      <div className="shop-title">
        {detail.title}
      </div>
      <Tabs
        value={formData.product_type}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label={t('shopping.food')} value={'food'}  />
        <Tab label={t('shopping.cleaningProduct')} value={'cleaningProduct'}/>
        <Tab label={t('shopping.cosmetics')} value={'cosmetics'} />
        <Tab label={t('shopping.lifeProduct')} value={'lifeProduct'} />
        <Tab label={t('shopping.plaything')} value={'plaything'} />
      </Tabs>
      <div className="shop-product">
        {list.map((row,index)=><div ref={index === list.length - 1 ? observe : null} key={row.product_id + index} className="product-item">
          <div className="product-item_left">
            <div className="item-left_img">
              <img src={api.url + row.banner} alt="" />
            </div>
            <div className="item-left_info">
              <div className="info-title">
                {row.vegetable_name}
              </div>
              <div className="info-date">
                最佳食用期: {row.product_arrival_date}
              </div>
              <div className="info-price">
                <div className="info-price_money">
                  {row.price}
                </div>
                <div className="info-price_unit">
                  時分
                </div>
              </div>
            </div>
          </div>
          <div className="product-item_right">
            <Button variant="contained" color="primary" onClick={()=>handleAppend(row)}>
              <img src={stateLayer} alt="" />
            </Button>
          </div>
        </div>)}
      </div>
      <AppendDialogDiv ref={AppendDialogRef} />
    </div>
  )
}
