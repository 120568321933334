import './index.less'
import React, { useEffect,useState } from 'react'
import api from '@/api'
import {useHistory} from 'react-router-dom'
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DateFormat from '@/utils/date'
import like from '@/assets/images/card/like.svg'
import IconlikeActive from '@/assets/images/user/like.svg'
import view from '@/assets/images/card/view.svg'
import { useTranslation } from 'react-i18next'
import {shiftTime} from '@/utils/shifttime'
function CardModule ({data}){
  const history = useHistory()
  const { t } = useTranslation()
  const [detail,setDetail ] = useState(data)
  const handleDetail = (e)=>{
    // if(states == '商品'){
    //   histroy.push('/shoppingshop')
    // }else{
    //   histroy.push('/shoppingvegetable')
    // }
    if(e.is_share == undefined){
      history.push(`/shoppingvegetable/?id=${e.event_id}`)
    }else{
      history.push(`/sharingdetails/?id=${e.event_id}`)
    }
    window.scrollTo(0, 0);
  }
  const handleCollect = async (e)=>{
    var collect_type = 0
    if(detail.is_share == undefined){
      collect_type =0
    }else{
      collect_type = 1
    }
    const {data,code,msg} = await api.user.PostUserCollect({
      event_id:e.event_id,
      collect_type
    })
    if(code!=0)return
    if(detail.collect_id){
      setDetail({ ...detail, ['collect_id']: '' })
    }else{
      setDetail({ ...detail, ['collect_id']: data.collect_id })
    }
  }
  return (
    <Card className="card">
      <div className="card-backdrop">
        {/* <img className="card-backdrop_banner" src={api.url + data?.image} alt="" /> */}
        <Avatar src={api.url + detail?.banner} alt={detail?.title} />
        <img className="card-backdrop_like" src={detail.collect_id?IconlikeActive:like} alt="" onClick={()=>handleCollect(detail)} />
      </div>
      <div className="card-info">
        <Avatar src={api.url + detail?.avatar} alt={detail?.title} />
        <div className="card-info_text">
          <div className="card-info_text-title">
            {detail?.title || 'null'}
          </div>
          <div className="card-info_text-tag">
            <span>
              {detail?.event_type || detail?.type}
            </span>
            <Divider orientation="vertical" flexItem />
            <span>
              {detail?.price || 20} {t('my.hm')}
            </span>
            <Divider orientation="vertical" flexItem />
            <span>
              {shiftTime(detail?.date || detail?.start_date)}
            </span>
          </div>
          <div className="card-info_text-date">
            {DateFormat(detail?.date || detail?.start_date)}, <br />
            {detail?.start_time} - {detail?.end_time} PM
          </div>
            {/*  onClick={()=>handleDetail(detail)} */}
          <Button variant="contained" color="primary" onClick={()=>handleDetail(detail)}>
            <img src={view} alt="" />
            {t('home.viewDetails')}
          </Button>
          {/* {!detail.is_share&&<Link to={`/shopping/vegetable/?id=${detail.event_id}`}>
            <Button variant="contained" color="primary">
              <img src={view} alt="" />
              {t('home.viewDetails')}
            </Button>
          </Link>} */}
        </div>
      </div>
    </Card>
  )
}

export default React.memo(CardModule)