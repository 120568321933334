// sharing form
import './index.less'
import React, { useState,useEffect, useRef, useMemo ,forwardRef} from 'react';
import {useHistory,useLocation,useParams} from 'react-router-dom'
import TopHead from '@/components/TopHead'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import api from '@/api'
import qs from 'query-string'
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import FormHelperText from '@mui/material/FormHelperText';
import PreviewDrawer from './components/preview'
import {tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import IconAdd from '@/assets/images/shear/add.svg'
import { useTranslation } from 'react-i18next'
const categoryObj = [
  {
    value: '2nd hand',
    label: '二手物',
  },
  {
    value: 'Hand made',
    label: '自家製',
  },
  {
    value: 'Class/Workshops',
    label: '開班/工作坊',
  },
  {
    value: 'Care Service',
    label: '照顧服務',
  },
  {
    value: 'Residential Service',
    label: '家居服務',
  },
  {
    value: 'Personal Service',
    label: '個人服務',
  },
  {
    value: 'other',
    label: '其他',
  },
];
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];
export default function SharingForm(){
  const { t } = useTranslation()
  const {search} = useLocation()
  const dispatch = useDispatch()
  const PreviewDrawerDiv = forwardRef(PreviewDrawer)
  const previewDrawerRef = useRef(null)
  const [status,setStatus] = useState(true)
  const [key,setKey] = useState({
    key1:'',
    key2:'',
    key3:''
  })
  const [formData,setFormData] = useState({
    event_id:'',
    banner: "", 
    image: "", 
    type: "", 
    title: "", 
    key: "", 
    detail: "", 
    price: 0, 
    pay_method: "", 
    start_date: "", 
    start_time: "", 
    end_time: "", 
    duration: "", 
    location: "", 
    tb_c: "", 
    which_marketplace: "",
    is_share:qs.parse(search).id
  })
  useEffect(()=>{
    if(qs.parse(search).event_id!= null){
      handleEventDetail()
    }
  },[])
  const handleEventDetail = async ()=>{
    const {data,code,msg} = await api.home.GetCommonEventActiveDetail({
      event_id:qs.parse(search).event_id
    })
    setKey({ ...key, ['key1']: data.key.split(',')[0]})
    setKey({ ...key, ['key2']: data.key.split(',')[1]})
    setKey({ ...key, ['key3']: data.key.split(',')[2]})
    // setFormData(data)
    var obj = formData
    for (let key in obj) {
      obj[key] = data[key]
    }
    setFormData({...obj})
  }
  const handlePreview = ()=>{
    // histroy('/sharing/preview')
    previewDrawerRef.current.handleOpen(formData)
  } 
  const handleUploadBanner = async (e)=>{
    const imageUrl = e.target.files[0]
    const formDataFile = new FormData();
    formDataFile.append('file', imageUrl);
    const {data,code,msg} = await api.user.PostCommonFile(formDataFile)
    if(code != 0)return 
    setFormData({ ...formData, ['banner']: data.src })
    setTimeout(()=>{
      e.target.value = ""
    },500)
  }
  const handleUploadImage = async (e)=>{
    const imageUrl = e.target.files[0]
    const formDataFile = new FormData();
    formDataFile.append('file', imageUrl);
    const {data,code,msg} = await api.user.PostCommonFile(formDataFile)
    if(code != 0)return 
    var obj = formData.image
    obj = obj + data.src + ','
    setFormData({ ...formData, ['image']: obj })
    setTimeout(()=>{
      e.target.value = ""
    },500)
  }
  const handleChangeDate = (date) => {
    const dt = date?.$d
    var year = dt.getFullYear()
    var month = dt.getMonth()+1
    var a = year+'-'+month+'-'+dt.getDate()
    var obj = formData;
    obj.start_date = a;
    obj.end_time ='00:00';
    obj.start_time = '00:00';
    setFormData(obj)
  };
  const handleChangeStartTime = (date) => {
    const dt = date?.$d
    var hour = dt.getHours(); 
    var minute = dt.getMinutes();
    if(hour < 10){
      hour = '0' + hour
    }
    if(minute < 10){
      minute = '0' + minute
    }
    var a = hour + ":" + minute
    setFormData({ ...formData, ['start_time']: a })
  };
  const handleChangeEndTime = (date) => {
    const dt = date?.$d
    var hour = dt.getHours(); 
    var minute = dt.getMinutes();
    if(hour < 10){
      hour = '0' + hour
    }
    if(minute < 10){
      minute = '0' + minute
    }
    var a = hour + ":" + minute
    setFormData({ ...formData, ['end_time']: a })
  };
  const handleverify = ()=>{
    formData.key = `${key.key1},${key.key2},${key.key3}`
    if(!formData.banner)return dispatch(tipsReveal({message:'請上傳圖片',type:'error'}))
    if(!formData.type)return setStatus(false)
    if(!formData.title)return setStatus(false)
    if(!formData.detail)return setStatus(false)
    if(!formData.price)return setStatus(false)
    if(!formData.pay_method)return setStatus(false)
    if(!formData.start_date)return setStatus(false)
    if(!formData.end_time)return setStatus(false)
    if(!formData.duration)return setStatus(false)
    if(!formData.location)return setStatus(false)
    if(!formData.tb_c)return setStatus(false)
    if(!formData.which_marketplace)return setStatus(false)
  }
  const handleSave = async()=>{
    formData.key = `${key.key1},${key.key2},${key.key3}`
    if(!formData.banner)return dispatch(tipsReveal({message:t('sharing.upload'),type:'error'}))
    if(!formData.type)return setStatus(false)
    if(!formData.title)return setStatus(false)
    if(!formData.detail)return setStatus(false)
    if(!formData.price)return setStatus(false)
    if(!formData.pay_method)return setStatus(false)
    if(!formData.start_date)return setStatus(false)
    if(!formData.end_time)return setStatus(false)
    if(!formData.duration)return setStatus(false)
    if(!formData.location)return setStatus(false)
    if(!formData.tb_c)return setStatus(false)
    if(!formData.which_marketplace)return setStatus(false)
    const {data,code,msg} = await api.shear.PostUserEvent(formData)
    if(code != 0)return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(tipsReveal({message:'创建成功',type:'success'}))
    window.history.go(-1)
  }
  const handleEdit = async () =>{
    formData.key = `${key.key1},${key.key2},${key.key3}`
    if(!formData.banner)return dispatch(tipsReveal({message:t('sharing.upload'),type:'error'}))
    if(!formData.type)return setStatus(false)
    if(!formData.title)return setStatus(false)
    if(!formData.detail)return setStatus(false)
    if(!formData.price)return setStatus(false)
    if(!formData.pay_method)return setStatus(false)
    if(!formData.start_date)return setStatus(false)
    if(!formData.end_time)return setStatus(false)
    if(!formData.duration)return setStatus(false)
    if(!formData.location)return setStatus(false)
    if(!formData.tb_c)return setStatus(false)
    if(!formData.which_marketplace)return setStatus(false)
    const {data,code,msg} = await api.shear.PutUserEvent({...formData})
    if(code != 0)return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(tipsReveal({message:'修改成功',type:'success'}))
    window.history.go(-1)
  }
  return (
    <>
      <input accept="image/*" id="icon-banner-file" className="icon-button-file" type="file" onChange={handleUploadBanner}/>
      <input accept="image/*" id="icon-image-file" className="icon-button-file" type="file" onChange={handleUploadImage}/>
      <div className="sharing-form">
        <TopHead title={t('sharing.share')} />
        {/* <PageCache> */}
        <div className="sharing-form_main">
          <form action="">
            <label className="form-upload" htmlFor="icon-banner-file">
              {formData.banner && <img className="form-uiload_img" src={api.url + formData.banner} alt="" />}
              {!formData.banner &&<label className="form-uiload_text" htmlFor="icon-banner-file">
                <div className="upload-text_title">
                  <img src={IconAdd} alt="" />
                  <span>{t('sharing.upload')}</span>
                </div>
                <div className="upload-text_des">
                  {t('sharing.uploadDetails')} <br />
                  {t('sharing.uploadDes')}
                </div>
              </label>}
            </label>
            <div className="form-list">
              {formData.image.split(",").map(item=>item && <div key={item} className="list-item"><img src={api.url + item}/></div>)}
              {formData.image.split(",").length<= 4 &&<label className="list-add" htmlFor="icon-image-file">
                <img src={IconAdd} alt="" />
              </label>}
            </div>
            <div className="form-input">
              <TextField
                select
                label={t('sharing.category')}
                variant="outlined"
                value={formData.type}
                name="type"
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.type || status?'':t('sharing.selectCategory')}
              >
                {categoryObj.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label={t('sharing.projectName')}
                multiline
                variant="outlined"
                name="title"
                value={formData.title}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.title || status?'':t('sharing.enterProjectName')}
              />
              <TextField
                label="Key 1"
                multiline
                variant="outlined"
                name="key1"
                value={key.key1}
                onChange={(event)=>{setKey({ ...key, [event.target.name]: event.target.value })}}
              />
              <TextField
                label="Key 2"
                multiline
                variant="outlined"
                name="key2"
                value={key.key2}
                onChange={(event)=>{setKey({ ...key, [event.target.name]: event.target.value })}}
              />
              <TextField
                label="Key 3"
                multiline
                variant="outlined"
                name="key3"
                value={key.key3}
                onChange={(event)=>{setKey({ ...key, [event.target.name]: event.target.value })}}
              />
              <div className="form-input_flex">
                <TextField
                  className="textfield-select"
                  select
                  label={t('sharing.paymentMethod')}
                  variant="outlined"
                  value={formData.pay_method}
                  name="pay_method"
                  onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                  helperText={formData.pay_method || status?'':t('sharing.selectPaymentMethod')}
                >
                  <MenuItem value={'微信支付'}>
                    微信支付
                  </MenuItem>
                  <MenuItem value={'支付宝支付'}>
                    支付宝支付
                  </MenuItem>
                </TextField>
                <TextField
                  label={t('sharing.price')}
                  multiline
                  variant="outlined"
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                  helperText={formData.price || status?'':t('sharing.enterPrice')}
                />
              </div>
              <TextField
                label={t('sharing.content')}
                multiline
                rows={4}
                variant="outlined"
                name="detail"
                value={formData.detail}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.detail || status?'':t('sharing.entweContent')}
              />
              <div className="form-picker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label={t('home.date')} name="start_date" onChange={handleChangeDate} defaultValue={dayjs(formData.start_date)} value={dayjs(formData.start_date)}/>
                  {formData.start_date || status?'':<FormHelperText>{t('sharing.selectDate')}</FormHelperText>}
                </LocalizationProvider>
              </div>
              <div className="form-input_flex">
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker label={t('sharing.startTime')}  name="start_time" onChange={handleChangeStartTime} defaultValue={dayjs(formData.start_date +'T'+formData.start_time)} value={dayjs(formData.start_date +'T'+formData.start_time)}/>
                    {formData.start_time || status?'':<FormHelperText>{t('sharing.selectStringTime')}</FormHelperText>}
                  </LocalizationProvider>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker label={t('sharing.endTime')}  name="end_time" onChange={handleChangeEndTime} defaultValue={dayjs(formData.start_date +'T'+formData.end_time)} value={dayjs(formData.start_date +'T'+formData.end_time)}/>
                    {formData.end_time || status?'':<FormHelperText>{t('sharing.selectEndTime')}</FormHelperText>}
                  </LocalizationProvider>
                </div>
              </div>
              <TextField
                label={t('sharing.period')}
                multiline
                variant="outlined"
                name="duration"
                value={formData.duration}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.duration || status?'':t('sharing.selectPeriod')}
              />
              <TextField
                label={t('sharing.locations')}
                multiline
                variant="outlined"
                name="location"
                value={formData.location}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.detail || status?'':t('sharing.enterLocations')}
              />
              <TextField
                select
                label={t('sharing.selectTime')}
                variant="outlined"
                name="tb_c"
                value={formData.tb_c}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.tb_c || status?'':t('sharing.pleaseSelectTime')}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label={t('sharing.whichMarket')}
                variant="outlined"
                name="which_marketplace"
                value={formData.which_marketplace}
                onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
                helperText={formData.which_marketplace || status?'':t('sharing.selectWhichMarket')}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form-button">
              <Button variant="outlined" color="primary" onClick={handlePreview}>
                {t('sharing.preview')}
              </Button>
              {qs.parse(search).event_id == null && <Button variant="contained" color="primary" onClick={handleSave}>
                {t('sharing.save')}
              </Button>}
              {qs.parse(search).event_id != null && <Button variant="contained" color="primary" onClick={handleEdit}>
                {t('sharing.editor')}
              </Button>}
            </div>
          </form>
        </div>
        {/* </PageCache> */}
      </div>
      <PreviewDrawerDiv ref={previewDrawerRef} />
    </>
  )
}

// function PageCache({ children }) {
//   const cacheContainer = useMemo(() => document.createElement('div'), []);
//   const [isCached, setIsCached] = useState(false);
 
//   const cacheRef = useRef(null);
//   if (!cacheRef.current) {
//     cacheRef.current = cacheContainer;
//     document.body.appendChild(cacheContainer);
//   }
 
//   if (isCached) {
//     return createPortal(children, cacheContainer);
//   } else {
//     return children;
//   }
// }
