import './index.less'
import api from '@/api'
import {useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch,useSelector } from 'react-redux';

import {tipsReveal,BalanceAction} from '@/redux/actions'
export default function Exchange(){
  const dispatch = useDispatch()
  const [formData,setFormData] = useState({
    money1:0,
    money2:0
  })
  const [toggle,setToggle] = useState(false)
  const [exChange,setExChange] = useState({})
  const redusxBalance = useSelector((state)=>{
    return state.UserRd.balance
  })
  const redusxSysBalance = useSelector((state)=>{
    return state.UserRd.sysBalance
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    acquireExchange()
  },[])
  useEffect(()=>{
    // setFormData(formData)
  },[formData])
  const acquireExchange = async ()=>{
    const {data,code,msg} = await api.user.GetCommonRates();
    setExChange(data)
  }
  const handleToggle = ()=>{
    setToggle(!toggle)
    var obj = formData
    obj.money1 = 0
    obj.money2 = 0
    setFormData({...obj})
  }
  const handleExchange = (money, user_type, rate_user_type)=>{
    if(money <= 0)return 0
    const ret_money = (money / exChange[user_type]) * exChange[rate_user_type]
    return ret_money
  }
  const handleNext = async ()=>{
    if(!formData.money1|| !formData.money2)return
    if(!toggle){
      console.log('平台转社区')
      const {data,code,msg} = await api.user.PostUserExchangeSysTb({
        money:formData.money1
      })
      if(code!=0)return dispatch(tipsReveal({message:msg,type:'error'}))
      dispatch(tipsReveal({message:'成功',type:'success'}))
      dispatch(BalanceAction())
      
    }else{
      console.log('社区转平台')
      const {data,code,msg} = await api.user.PostUserExchangeTb({
        money:formData.money1
      })
      if(code!=0)return dispatch(tipsReveal({message:msg,type:'error'}))
      dispatch(tipsReveal({message:'成功',type:'success'}))
      dispatch(BalanceAction())
    }
  }
  const handleMoney1 = (e)=>{
    var obj = formData
    obj.money1 = Number(e.target.value)
    if(toggle){
      console.log('社区转平台')
      const money = handleExchange(e.target.value,redusxUser.user_type,0)
      obj.money2 = money
    }else{
      console.log('平台转社区')
      const money = handleExchange(e.target.value,0,redusxUser.user_type)
      obj.money2 = money
    }
    console.log(obj)
    setFormData({...obj})
  }             
  const handleMoney2 = (e)=>{
    var obj = formData
    obj.money2 = Number(e.target.value)
    if(toggle){
      console.log('社区转平台')
      const money = handleExchange(e.target.value,0,redusxUser.user_type)
      obj.money1 = money
    }else{
      console.log('平台转社区')
      const money = handleExchange(e.target.value,redusxUser.user_type,0)
      obj.money1 = money
    }
    console.log(obj)
    setFormData({...obj})
  }
  return (
    <>
      <div className="exchange">
        <div className="exchange-card">
          <div className="card-main">
            <div className="card-main_head">
              <div className="main-head_title">
                {toggle?'社区币':'平台币'}
              </div>
              <div className="main-head_balance">
                Balance:{toggle?redusxBalance:redusxSysBalance}
              </div>
            </div>
            <div className="card-main_input">
              <TextField
                multiline
                type="number"
                id="filled-hidden-label-small"
                name="money1"
                value={formData.money1}
                defaultValue={formData.money1}
                onChange={(event)=>{handleMoney1(event)}}
              />
            </div>
          </div>
          <div className="card-toggle" onClick={()=>handleToggle()}>
            <svg t="1722332980034" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7545"><path d="M512 128a42.666667 42.666667 0 0 1 42.666667 42.666667v579.370666l204.373333-204.373333a42.666667 42.666667 0 0 1 63.914667 56.277333l-3.584 4.010667-277.376 277.546667a42.666667 42.666667 0 0 1-56.32 3.584l-4.010667-3.541334-277.12-276.650666a42.666667 42.666667 0 0 1 56.234667-63.957334l4.010666 3.541334L469.333333 750.592V170.666667a42.666667 42.666667 0 0 1 42.666667-42.666667z" fill="#0CD2C7" p-id="7546"></path></svg>
          </div>
          <div className="card-main">
            <div className="card-main_head">
              <div className="main-head_title">
                {!toggle?'社区币':'平台币'}
              </div>
              <div className="main-head_balance">
                Balance:{!toggle?redusxBalance:redusxSysBalance}
              </div>
            </div>
            <div className="card-main_input">
              <TextField
                multiline
                id="filled-hidden-label-small"
                type="number"
                value={formData.money2}
                name="money2"
                defaultValue={formData.money2}
                onChange={(event)=>{handleMoney2(event)}}
              />
            </div>
          </div>
          <Button variant="contained" color="primary" onClick={handleNext}>
            确定
          </Button>
        </div>
      </div>
    </>
  )
}