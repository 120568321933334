import axios from "axios"
import {getToken,removeToken} from '@/utils/auth'
export const url = 'http://47.92.141.102:5005/'

const service = axios.create({
  baseURL: url,
  timeout: 500000
})

service.defaults.headers.post['Content-Security-Policy'] = 'upgrade-insecure-requests'
service.interceptors.request.use(config => {
  const token = getToken()
  token && (config.headers.token = token)
  return config
}, error =>{
  Promise.reject(error)
})
service.interceptors.response.use(response => {
  return response.data
}, error => {
  if(error.response.data.msg == '请重新登录'){
    history.go(0)
    removeToken()
    Promise.reject({data:'退出登录'})
  }
  Promise.reject(error)
})
export const baseURL = url
export default service
