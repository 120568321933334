function Precise(num1,num2){
  var total = num1 * num2
  if (JSON.stringify(num1).indexOf('.') != -1 ||JSON.stringify(num2).indexOf('.') != -1) { //num1或者num2为浮点数
      // 已小数点为分界线拆分成数组。
      var floatNum1 = JSON.stringify(num1).split('.')
      var floatNum2 = JSON.stringify(num2).split('.')
      // 获取小数点的位数，如果没有小数点则改为0
      floatNum1 = floatNum1.length == 2 ? floatNum1[1].length : 0
      floatNum2 = floatNum2.length == 2 ? floatNum2[1].length : 0
      // 将小数点位数相加
      var index = floatNum1 + floatNum2
      // 取近似值Math.round
      total = Math.round(total * (10 ** index)) / (10 ** index);
  }
  return total
}
export default Precise