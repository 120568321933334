import './index.less';
import React ,{useState,useImperativeHandle, useEffect} from 'react'
import api from '@/api'
import {useLocation,useHistory } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import IconTExLogo from '@/assets/images/icon/TExLogo.svg'
import IconFinishTime from '@/assets/images/shear/Finish_time.svg'
import IconFinishTimeActive from '@/assets/images/shear/Finish_time_active.svg'
import IconPause from '@/assets/images/icon/pause.svg'
// function usePageReturn(open,handleClose) {
//   const location = useLocation();
//   const histroy = useNavigate()
//   console.log(location)
//   useEffect(() => {
//     if(open){
//       window.history.pushState(null,'',document.URL)
//       window.onpopstate = function(){
//         window.history.pushState(null,'',document.URL)
//       }
//     }
    
//     return () => {
//       if(open){
//         handleClose()
//         window.onpopstate = null
//       }
//     }
//   }, [open]);
// }
export default function ServiceDialog(props,ref){
  const [open,setOpen] = useState(false)
  const [detail,setDetail] = useState({
    avatar:'',
    username:'',
    event_id:'',
    start_time:'', // 开始时间
    end_time:'', // 结束时间
  })
  const [schedule,setSchedule] = useState(0)
  const [time,setTime] = useState(null)
  const [percent,setPercent] = useState(0)
  const [info,setInfo] = useState(0)
  const [status,setStatus] = useState(false)
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setStatus(false)
      setDetail(e)
      setOpen(true)
      if(e.is_share == 0){
        handleDetail(e.user_event_uid)
      }else{
        handleDetail(e.uid)
      }
      if(e.start_time){
        handleInitiate(e)
      }
    }
  }))
  useEffect(()=>{
    if(schedule>=100) clearInterval(time) 
  },[schedule])
  useEffect(()=>{
    if(percent == 0)return 
    handleStartTheClock() 
  },[percent])
  const handleClose = ()=>{
    clearInterval(time)
    setStatus(true)
    setOpen(false)
  }
  const handleDetail = async (uid)=>{
    const {data,code,msg} = await api.shear.PostUserInfoView({
      uid
    })
    console.log(data)
    setInfo(data)
  }
  // usePageReturn(open,handleClose)
  const handleStartTheClock = ()=>{
    setTime(setInterval(()=>{
      setSchedule((schedule)=>{
        return schedule + percent
      })
    },1000))
  }
  const handleStart = async ()=>{
    const {data,code,msg} = await api.shear.PostUserEventJoinStart({
      event_id: detail.event_id, 
      is_choose: 1
    })
    if(code != 0)return 
    handleInitiate(detail)
    // console.log(data,code,msg)
  }
  const handleInitiate = (e)=>{
    var start_hour = e.user_event_start_time.split(':')[0];
    var start_min = e.user_event_start_time.split(':')[1];
    var start_s = Number(start_hour) * 3600 + Number(start_min) * 60
    var end_hour = e.user_event_end_time.split(':')[0];
    var end_min = e.user_event_end_time.split(':')[1];
    var end_s = Number(end_hour) * 3600 + Number(end_min) * 60
    console.log(end_hour,end_min,end_s)
    var num = 100 / (end_s - start_s)
    setPercent(num)
  }
  const handlePause = ()=>{
    if(!status){
      console.log('暂停')
      clearInterval(time)
      setStatus(true)
    }else{
      console.log('开始')
      handleStartTheClock() 
      setStatus(false)
    }
    
  }
  const handleProcess = async()=>{
    const {data,code,msg} = await api.shear.PostUserEventJoinEnd({
      event_id: detail.event_id, 
    })
    if(code != 0 )return 
    handleClose()
  }
  return (
    <>
      <Dialog className="service-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className="service-main">
          <div className="service-main_head">
            <Avatar src={api.url + info.image} alt={info.username}/>
            <div className="main-head_info">
              <div className="info-item">
                <span className='info-item_label'>
                  Has
                </span>
                <img src={IconTExLogo} alt="" />
                <span className="info-item_number">{info.tb}</span>
              </div>
              <div className="info-item">
                <span className='info-item_label'>
                  Left with
                </span>
                <img src={IconTExLogo} alt="" />
                <span className="info-item_number">{info.tb - detail.price}</span>
              </div>
              <Rating name="read-only" value={info?.score_data?.score || 0} precision={0.5} readOnly />
            </div>
          </div>
          <div className="service-main_name">
            {detail.username}
          </div>
          <div className="service-main_date">
            <img src={IconFinishTime} alt="" />
            <div className="service-main_date-text">
              Start time
            </div>
            <div className="service-main_date-active" style={{height:schedule + '%'}}>
              <img className="service-main_date-active" src={IconFinishTimeActive} alt="" />
            </div>
          </div>
          <div className="service-main_btn">
            {schedule == 0&& <Button variant="contained" color="primary" onClick={handleStart}>
              Start
            </Button>}
            {schedule != 0&& 
            <>
              <Button className="mian-btn_Pause" variant="outlined" color="primary" onClick={handlePause}>
                <img src={IconPause} alt="" />
                {!status ?'Pause':'Initiate'}
              </Button>
              <Button variant="contained" color="primary" onClick={handleProcess}>
                Process Payment
              </Button>
            </>
            }
          </div>
        </div>
      </Dialog>
    </>
  )
}