import api from "@/api"
function ShopAction(){
  return async (dispatch) => {
      const {data,code,msg,total} = await api.shop.GetCart()
      if(code != 0)return {data,code,msg}
      dispatch({
        type:"COUNT",
        value:total,
      })
      if(data.length == undefined){
        dispatch({
          type:"SHOP",
          value:[],
        })
        return 
      } 
      dispatch({
        type:"SHOP",
        value:data,
      })
      return {data,code,msg}
  }
}

export {
  ShopAction
}