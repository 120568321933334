import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.js'
import './index.less'
import store from "@/redux"
import { Provider } from 'react-redux'
import '@/utils/exit.js'
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
)
reportWebVitals();
