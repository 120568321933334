import './index.less';
import {useHistory,useLocation} from 'react-router-dom'
import qs from 'query-string'
import IconTExLogo from '@/assets/images/icon/TExLogo.svg'
import IconGoldCoin from '@/assets/images/shear/gold_coin.svg'
import Button from '@mui/material/Button';
import api from '@/api'
import { useEffect , useState} from 'react';
export default function SharingPayment(){
  const histroy = useHistory()
  const [details,setDetails] = useState({
    price:'',
    event_id:''
  })
  const { search } = useLocation()
  useEffect(()=>{
    handleEventDetail()
  },[])
  const handleEventDetail = async()=>{
    const {data,code,msg} = await api.home.GetCommonEventActiveDetail({
      event_id:qs.parse(search).id
    })
    setDetails(data)
  }
  const handlePayNow = async ()=>{
    // {replace:true}
    const {data,code,msg} = await api.shear.PostUserEventPay({
      event_id:qs.parse(search).id
    })
    if(code != 0) return 
    histroy.replace(`/sharingmark/?id=${details.event_id}`)
  }
  return (
    <>  
      <div className="payment">
        <div className="payment-img">
          <img className="payment-img" src={IconGoldCoin} alt="" />
        </div>
        <div className="payment-money">
          <div className="payment-money_send">
            Send
          </div>
          <div className="payment-money_num">
            <img src={IconTExLogo} alt="" />
            <span>{details.price}</span>
            to Tom
          </div>
        </div>
        <Button variant="contained" color="primary" onClick={handlePayNow}>
          Pay now
        </Button>
      </div>
    </>
  )
}