import './index.less'
import React,{useEffect,useState,useImperativeHandle } from 'react'
import Dialog from '@mui/material/Dialog';
import api from '@/api'
import {useHistory,Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import {tipsReveal,BalanceAction} from '@/redux/actions'
import { useDispatch } from 'react-redux'
const TransferDialog = (props,ref) => {
  const histroy = useHistory()
  const [open,setOpen] = useState(false)
  const dispatch = useDispatch()
  const [formData,setFormData] = useState({
    wallet_id:'',
    money:'',
    is_sys:false
  })
  useEffect(()=>{
    
  },[])
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setOpen(true)
    }
  }))
  const handleClose = ()=>{
    var obj = formData
    obj.wallet_id = ''
    obj.money = ''
    setFormData(obj)
    // setFormData({ ...formData, ['money']: '' })
    setOpen(false)            
  }
  const handleNext = async()=>{
    if(!formData.wallet_id)return 
    if(!formData.money)return 
    const {data,code,msg} = await api.user.PostUserWalletTran(formData)
    console.log(data,code,msg)
    if(code!=0)return dispatch(tipsReveal({message:msg,type:'error'}))
    dispatch(tipsReveal({message:'Successful',type:'success'}))
    dispatch(BalanceAction())
    handleClose()
  }
  return (
    <>
      <Dialog className="transfer-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className="transfer-title">
          转账
        </div>
        <div className="transfer-main">
          <TextField
            label={`钱包地址*`}
            name='wallet_id'
            multiline
            variant="outlined"
            defaultValue={formData.wallet_id}
            onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            helperText={!formData.wallet_id && '请输入钱包地址'}
          />
          <TextField
            label={`金额*`}
            name='money'
            type="number"
            variant="outlined"
            defaultValue={formData.money}
            onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            helperText={!formData.money && '请输入转账金额'}
          />
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-label">
              币种*
            </InputLabel>
            <Select
              name='is_sys'
              value={formData.is_sys}
              onChange={(event)=>{setFormData({ ...formData, [event.target.name]: event.target.value })}}
            >
              <MenuItem value={false}>社区币</MenuItem>
              <MenuItem value={true}>平台币</MenuItem>
            </Select>
            {/* {formData.money?'':<FormHelperText>请选择币种类型</FormHelperText>} */}
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        </div>
        
      </Dialog>
    </>
  )
}
export default TransferDialog