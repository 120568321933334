// marketplace -- 
import {useState,useEffect,useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import Location_TimeExLogo_Both1 from '@/assets/images/home/Location_TimeExLogo_Both1.svg'
import Location_TimeExLogo_Both2 from '@/assets/images/home/Location_TimeExLogo_Both2.svg'
import Location_TimeExLogo_Both3 from '@/assets/images/home/Location_TimeExLogo_Both3.svg'
import Location_Character from '@/assets/images/home/Location_Character1.svg'
import Location from '@/assets/images/home/Location.svg'
import Wc from '@/assets/images/home/WC.svg'
import Wc2 from '@/assets/images/home/WC2.svg'
import Wc3 from '@/assets/images/home/WC3.svg'
import IconLogout from '@/assets/images/home/logout.svg'
import './index.less'
import { useTranslation } from 'react-i18next'
import { useDispatch,useSelector } from 'react-redux'
import api from '@/api'
import {logoutAction,UserClearAction,UserAction} from '@/redux/actions'
import CardModule from './Card'
import Contact from './Contact'
import {getToken} from '@/utils/auth'
export default function Home(){
  const histroy = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [list,setList] = useState([])
  const [contactOpen,setContactOpen] = useState(false)
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    

  },[])
  const handleContactDetails = useCallback((e)=>{
    setContactOpen(e)
  },[])
  const handleLogout = ()=>{
    histroy.replace('/entrancelogin')
    dispatch(logoutAction())
    dispatch(UserClearAction())
  }
  return (
    <div className='marketplace'>
      {/* <Tourist /> */}
      <div className='marketplace-head'>
      <img className='timeExLogo' src={redusxUser.user_type == 1 && Location_TimeExLogo_Both1 || redusxUser.user_type == 2 && Location_TimeExLogo_Both2 || redusxUser.user_type == 3 && Location_TimeExLogo_Both3} alt="" />
        <div className="tourist-head_right">
          {redusxUser?.uid&&<img src={redusxUser.user_type == 1 && Wc || redusxUser.user_type == 2 && Wc2 || redusxUser.user_type == 3 && Wc3} alt="" onClick={()=>handleContactDetails(true)}/>}
          <img src={Location_Character} alt="" />
          {getToken()&&<div className="logout">
            <img src={IconLogout} alt="" onClick={()=>handleLogout()} />
          </div>}
        </div>
      </div>
      {<User />}
      <Contact contactOpen={contactOpen} handleContactDetails={handleContactDetails} />
    </div>
  )
}

function Tourist(){
  return (
    <div className='tourist'>
      {/* <div className='tourist-head'>
        <img className='timeExLogo' src={Location_TimeExLogo_Both} alt="" />
        <div className="tourist-head_right">
          <img src={Location_Character} alt="" />
        </div>
      </div> */}
      <div className="tourist-city">
        <img src={Location} alt="" />
        <span className="tourist-city_text">
          我係長沙灣區
        </span>
      </div>
      <div className="card-list">
        {[1,2,3,4,5].map((item)=><CardModule key={item}/>)}
      </div>
      <div className="tourist-city">
        <img src={Location} alt="" />
        <span className="tourist-city_text">
          我係長沙灣區
        </span>
      </div>
      <div className="card-list">
        {[1,2,3,4,5].map((item)=><CardModule key={item}/>)}
      </div>
    </div>
  )
}

function User(){
  const { t } = useTranslation()
  const [productList,setProductList] = useState([])
  const [eventList,setEventList] = useState([])
  useEffect(()=>{
    acquireProduct()
    acquireEvent()
  },[])
  const acquireProduct = async ()=>{
    const {data,code,msg} = await api.home.GetCommonEvent();
    if(data.length == undefined)return 
    setProductList(data)
  }
  const acquireEvent = async ()=>{
    const {data,code,msg} = await api.home.GetCommonEventActive();
    if(data.length == undefined)return 
    setEventList(data)
  }
  return (
    <>
      <div className='user tourist'>
        <div className="tourist-city">
          <span className="tourist-city_text">
            {t('home.product')}
          </span>
        </div>
        <div className="card-list">
          {productList.map((row)=><CardModule key={row.event_id} data={row}/>)}
        </div>
        <div className="tourist-city">
          <span className="tourist-city_text">
            {t('home.event')}
          </span>
        </div>
        <div className="card-list">
          {eventList.map((row)=><CardModule key={row.event_id} data={row}/>)}
        </div>
      </div>
      
    </>
  )
}

