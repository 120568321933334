export default function reducer(prevState ={
  list:[],
  count:0,
},action){
  var newStete = {...prevState}
  switch(action.type){
    case "SHOP" :
      newStete.list = action.value
      return newStete
    case "COUNT" :
      newStete.count = action.value
      return newStete
    default:
      return newStete
  }
}