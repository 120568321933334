// code
import './index.less'
import {useState,useEffect,useRef} from 'react'
import { useLocation,useHistory } from 'react-router-dom';
import api from '@/api'
import qs from 'query-string'
import {loginAction,tipsReveal,UserAction,BalanceAction,ShopAction} from '@/redux/actions'
import { useDispatch } from 'react-redux'
export default function Code(){
  const histroy = useHistory()
  const dispatch = useDispatch()
  const {search} = useLocation()
  const reg = /^[0-9]{0,4}$/;
  const [value,setValue] = useState()
  const [input,setInput] = useState(null)
  const refInput = useRef()
  const items = document.querySelectorAll('.ipt-item')
  useEffect(()=>{
    setInput(document.querySelector('.code-input_hidden'))
  },[])
  const handleFocus = ()=>{
    // input.setSelectionRange(input.value.length - 1,input.value.length)
    const val = input.value
    if(!val){
      items[0].classList.add('ipt-item_active')
      return 
    }
    if(val.length<items.length){
      items[val.length].classList.add('ipt-item_active')
      return 
    }
    if(val.length == items.length){
      items[val.length-1].classList.add('ipt-item_active')
      return 
    }
  }
  const handleBlur = ()=>{
    items.forEach(item=>{
      item.classList.remove('ipt-item_active')
    })
  }
  const handleInput = async (e)=>{
    items.forEach(item=>{
      item.textContent = '';
      item.classList.remove('ipt-item_active');
    })
    const val = e.target.value;
    if(reg.test(val)){
      setValue(val)
    }else{
      input.value = value
    }
    const arr = input.value.split('');
    if(!arr.length){
      items[0].classList.add('ipt-item_active')
    }
    arr.forEach((item,index)=>{
      items[index].textContent = item
      items[index].classList.remove('ipt-item_active')
      if(items[index+1]){
        items[index+1].classList.add('ipt-item_active')
      }
      if(index == items.length - 1){
        items[index].classList.add('ipt-item_active')
      }
    })
    if(input.value.length == 4){
      console.log(histroy.location.state.state)
      var phone_number = '+'+histroy.location.state.state.phone_code + histroy.location.state.state.phone_number
      if(histroy.location.state.state.id == 1){
        const {data,code,msg} = await api.user.PostUserRegister({
          sms_code:input.value,
          ...histroy.location.state.state,
          phone_number
        })
        console.log(data,code,msg)
        if(code != 0){
          dispatch(tipsReveal({message:msg,type:'error'}))
          return
        }
        const message = await dispatch(loginAction(histroy.location.state.state))
        if(message.code != 0){
          dispatch(tipsReveal({message:message.msg,type:'error'}))
          return 
        } 
        dispatch(UserAction())
        dispatch(BalanceAction())
        dispatch(ShopAction())
        histroy.replace('/home')
      }else{
        const {data,code,msg} = await api.user.PostUserForgetPassword({
          sms_code:input.value,
          ...histroy.location.state.state,
          phone_number
        })
        if(code != 0){
          dispatch(tipsReveal({message:msg,type:'error'}))
          return
        }
        dispatch(tipsReveal({message:'修改成功',type:'success'}))
        histroy.replace('/entrancelogin')
      }
      
    }
  }
  return (
    <div className='code'>
      <div className="code-title">
        Verification Code
      </div>
      <div className="code-text">
        "Please enter the Verification<br/> Code from your SMS below:"
      </div>
      <div className="code-input">
        <input ref={refInput} type="number" className="code-input_hidden" onFocus={()=>handleFocus()} onBlur={()=>handleBlur()} onInput={(e)=>handleInput(e)} />
        <div className="code-input_list">
          <div className="ipt-item">
          </div>
          <div className="ipt-item">
          </div>
          <div className="ipt-item">
          </div>
          <div className="ipt-item">
          </div>
        </div>
      </div>
    </div>
  )
}

