// Transition
import './index.less';
import {useState,useEffect,useRef} from 'react'
import api from '@/api'
import TopHead from '@/components/TopHead'
import { useInView } from "react-cool-inview";
import {useHistory,Link} from 'react-router-dom'
export default function Transition(){
  const histroy = useHistory()
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const [formData,setFormData] = useState({
    page:1,
  })
  useEffect(()=>{
    handleWalletBills()
  },[])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
      handleWalletBills()
    },
  });
  const handleWalletBills = async ()=>{
    const {data,code,msg,total:count} = await api.shop.GetUserWalletBills(formData)
    setTotal(count)
    if(data.length == undefined) {
      setList([])
      return 
    } 
    setList([...list,...data])
    
  }
  const handlePush = (e)=>{
    histroy.push(
      `/usercheck/?id=${e.bills_id}`
    )
  }
  return (
    <div className='transition'>
      <TopHead title='Transition' />
      <div className="transition-main">
        <div className="transition-main_list">
          {/* onClick={()=>handlePush(item)} */}
          {list.map((item,index)=><Link ref={index === list.length - 1 ? observe : null} key={item.bills_id} className="list-item" to={`/usercheck/?id=${item.bills_id}`}>
            <div className="list-item_left">
              <div className="left-date">
                {item.create_time}
              </div>
              <div className="left-text">
                {item.msg}
              </div>
            </div>
            <div className="list-item_right">
              {item.re_tb}
            </div>
          </Link>)}
        </div>
      </div>
    </div>
  )
}

