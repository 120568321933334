import api from "@/api"
function UserAction(){
  return async (dispatch) => {
    try{
      const {data,code,msg} = await api.user.GetUserInfo()
      if(code != 0)return {data,code,msg}
      dispatch({
        type:"USER",
        value:data,
      })
      return {data,code,msg}
    }
    catch(e){
      return {data:'logOut'}
    }
  }
}
function UserClearAction(){
  return {
    type:"USERCLEAR",
    value:[]
  }
}

function BalanceAction(){
  return async (dispatch) => {
    const {data,code,msg} = await api.user.GetUserWallet()
    if(code != 0)return {data,code,msg}
    console.log(data)
    dispatch({
      type:"BALANCE",
      value:data,
    })
    return {data,code,msg}
  }
}
export {
  UserAction,
  UserClearAction,
  BalanceAction
}