import './index.less';
import api from '@/api'
import {useState} from 'react'
import {useHistory,useLocation} from 'react-router-dom'
import qs from 'query-string'
import IconMark from '@/assets/images/shear/mark.svg'
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import {loginAction,tipsReveal} from '@/redux/actions'
import { useDispatch } from 'react-redux'
import AppendDialog from '@/views/shopping/shop/appendDialog';
export default function SharingMark(){
  const {search} = useLocation()
  const dispatch = useDispatch()
  const histroy = useHistory()
  const [value,setValue] = useState(0)
  const handleSubmit = async()=>{
    console.log(value)
    if(value == null || value == 0){
      dispatch(tipsReveal({message:'请选择评分',type:'error'}))
      return 
    }
    const {data,code,msg} = await api.shear.PostUserEventJoinScore({
      event_id:qs.parse(search).id,
      score:value
    })
    if(code!=0)return dispatch(tipsReveal({message:msg,type:'error'}))
    window.history.go(-1)
  }
  return (
    <>
      <div className="sharingmark">
        <div className="sharingmark-img">
          <img src={IconMark} alt="" />
        </div>
        <div className="sharingmark-title">
          Credits Send out
        </div>
        <div className="sharingmark-des">
          Rate your interaction with Tom
        </div>
        <Rating name="half-rating" value={value} precision={0.5} onChange={(event, newValue) => {
           setValue(newValue);
        }}/>
        <div className="sharingmark-btn">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </>
  )
}