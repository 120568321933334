import './index.less'
import React,{useEffect} from 'react'
import Dialog from '@mui/material/Dialog';
import {useHistory,Link } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Icon2ndhand from '@/assets/images/shear/2ndhand.svg'
import IconHandmade from '@/assets/images/shear/handmade.svg'
import IconClass from '@/assets/images/shear/class.svg'
import IconCard from '@/assets/images/shear/card.svg'
import IconResidential from '@/assets/images/shear/residential.svg'
import IconPersonal from '@/assets/images/shear/personal.svg'
import IconOther from '@/assets/images/shear/other.svg'
const categoryObj = [
  {
    value: '2nd hand',
    label: '二手物',
  },
  {
    value: 'Hand made',
    label: '自家製',
  },
  {
    value: 'Class/Workshops',
    label: '開班/工作坊',
  },
  {
    value: 'Care Service',
    label: '照顧服務',
  },
  {
    value: 'Residential Service',
    label: '家居服務',
  },
  {
    value: 'Personal Service',
    label: '個人服務',
  },
  {
    value: 'other',
    label: '其他',
  },
];
function ShearDialog({shearOpen,handleDialogDetails,value}){
  console.log(value)
  const histroy = useHistory()
  useEffect(()=>{
    
  },[])
  const handleClose = ()=>{
    handleDialogDetails(false)
  }
  const handleForm = ()=>{
    histroy.push(`/sharingform/?id=${value}`)
  }
  return (
    <>
      <Dialog className="shear-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={shearOpen}>
        <div className="shear-dialog_main">
          <div className="dialog-main_title">
            與我的社群分享
          </div>
          <div className="dialog-main_list">
            <div className="list-item" onClick={()=>handleForm()}>
              <img src={Icon2ndhand} alt="" />
              <div className="list-item_info">
                <div className="list-item_info-title">
                  二手物
                </div>
                <div className="list-item_info-text">
                  2nd hand
                </div>
              </div>
            </div>
            <div className="list-item" onClick={()=>handleForm()}>
              <img src={IconHandmade} alt="" />
              <div className="list-item_info">
                <div className="list-item_info-title">
                  自家製
                </div>
                <div className="list-item_info-text">
                  Hand made
                </div>
              </div>
            </div>
            <div className="list-item" onClick={()=>handleForm()}>
              <img src={IconClass} alt="" />
              <div className="list-item_info">
                <div className="list-item_info-title">
                  開班/工作坊 
                </div>
                <div className="list-item_info-text">
                  Class/Workshops
                </div>
              </div>
            </div>
            <div className="list-item" onClick={()=>handleForm()}>
              <img src={IconCard} alt="" />
              <div className="list-item_info">
                <div className="list-item_info-title">
                  照顧服務 
                </div>
                <div className="list-item_info-text">
                  Care Service
                </div>
              </div>
            </div>
            <div className="list-item" onClick={()=>handleForm()}>
              <img src={IconResidential} alt="" />
              <div className="list-item_info">
                <div className="list-item_info-title">
                  家居服務 
                </div>
                <div className="list-item_info-text">
                  Residential Service
                </div>
              </div>
            </div>
            <div className="list-item" onClick={()=>handleForm()}>
              <img src={IconPersonal} alt="" />
              <div className="list-item_info">
                <div className="list-item_info-title">
                  個人服務
                </div>
                <div className="list-item_info-text">
                  Personal Service
                </div>
              </div>
            </div>
            <div className="list-item" onClick={()=>handleForm()}>
              <img src={IconOther} alt="" />
              <div className="list-item_info">
                <div className="list-item_info-title">
                  其他
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
export default React.memo(ShearDialog)