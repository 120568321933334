import api from "@/api"
import {setToken,setCipher,removeCipher} from '@/utils/auth'
function loginAction(password){
  return async (dispatch) => {
    var formData = {...password}
    formData.phone_number = '+'+password.phone_code+password.phone_number
    const {data,code,msg} = await api.user.PostUserLogin(formData)
    if(code != 0)return {data,code,msg}
    setToken(data.token)
    if(password.bool){
      setCipher(JSON.stringify(password))
    }else{
      removeCipher()
    }
    dispatch({
      type:"LOGIN",
      // value:data,
    })
    return {data,code,msg}
  }
}

function logoutAction(){
  return {
    type:"LOGOUT",
    value:true
  }
}
export {
  loginAction,
  logoutAction
}