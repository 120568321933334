import {useState,useEffect,useRef} from 'react'
import QRCode from 'qrcode-react';
import jsQR from 'jsqr';
import './index.less'
import api from '@/api'
export default function QrCode(){
  const [qrCodeData, setQrCodeData] = useState('https://postimg.cc/jnsdQndk');
  // const videoRef = useRef(null);
  // useEffect(() => {
  //   const loadVideo = async () => {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     videoRef.current.srcObject = stream;
  //   };
  //   loadVideo();
  // }, []);
 
  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.addEventListener('play', () => {
  //       const scan = setInterval(() => {
  //         if (videoRef.current) {
  //           const canvas = document.createElement('canvas');
  //           const context = canvas.getContext('2d');
  //           canvas.width = videoRef.current.videoWidth;
  //           canvas.height = videoRef.current.videoHeight;
  //           context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
  //           const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  //           const code = jsQR(imageData.data, canvas.width, canvas.height);
  //           if (code) {
  //             clearInterval(scan);
  //             setQrCodeData(code.data);
  //           }
  //         }
  //       }, 200);
  //     });
  //   }
  // }, [videoRef]);
  useEffect(()=>{
    handleQrCode()
  },[])
  const handleQrCode = async ()=>{
    const {data,code,msg} = await api.user.GetUserWalletQr()
    console.log(data,code,msg)
    setQrCodeData(data.qr)
  }
  return (
    <div className="qrCode">
      {/* <video ref={videoRef} autoPlay style={{ width: 300 }} /> */}
      <QRCode
        value={qrCodeData}
        className='qrcode'
        style={{
          width: 296,
          height: 296,
          zIndex: 296,
          position: 'relative'
        }}
        level={'H'} // 可选，可以接受7,15,25,30程度的容错级别，例如'L', 'M', 'Q', 'H'
        bgColor='transparent' // 设置二维码背景为透明
      />
    </div>
  )
}