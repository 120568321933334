import './index.less'
import React,{useEffect, useState} from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import {useHistory,useLocation } from 'react-router-dom'
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import photo_camera from '@/assets/images/user/photo_camera.svg'
import photo_library from '@/assets/images/user/photo_library.svg'
import Dots from '@/assets/images/user/Dots.svg'
import api from '@/api'
import {UserAction,tipsReveal} from '@/redux/actions'
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
export default function UserNew(){
  const { t } = useTranslation()
  const histroy = useHistory()
  const dispatch = useDispatch()
  const [progress,setProgress] = useState(0)
  const [status,setStatus] = useState(true)
  const [state,setState] = useState({
    phone_number:'',
    username: "", 
    image: "", 
    first_name: "", 
    last_name: "", 
    gender: "", 
    birth: "", 
    address: "", 
    district: "", 
    region: "", 
    email: "", 
    edu_level: "", 
    employment_status: "", 
    household_income: "", 
    marital_status: "", 
    of_members: '', 
    age_distribution: "", 
    personal_skills: "" 
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(()=>{
    setState(redusxUser)
    
  },[redusxUser])
  const handleCancel = ()=>{
    if(progress == 0){
      window.history.go(-1)

    }else{
      var num = progress - 25
      setProgress(num)
    }
  }
  const handleNext = async()=>{
    setStatus(true)
    if(progress == 0){
      if(!state.image){
        dispatch(tipsReveal({message:'請上傳頭像',type:'error'}))
        return 
      }
    }else if(progress == 25){
      if(!state.first_name)return setStatus(false)
      if(!state.last_name)return  setStatus(false)
      if(!state.username)return setStatus(false)
      if(!state.gender)return setStatus(false)
      if(!state.birth)return  setStatus(false)
    }else if(progress == 50){
      if(!state.address)return setStatus(false)
      if(!state.district)return  setStatus(false)
      if(!state.region)return setStatus(false)
    }
    else if(progress == 75){
      if(!state.edu_level)return setStatus(false)
      if(!state.employment_status)return  setStatus(false)
    }else{
      const {data,code,msg} = await api.user.PostUserInfo(state)
      if(code != 0){
        dispatch(tipsReveal({message:msg,type:'error'}))
        return 
      }
      dispatch(UserAction())
      window.history.go(-1)
    }
    if(progress<100){
      var num = progress + 25
      setProgress(num)
    }
    
  }
  return (
    <>
      <div className='new-user'>
        <LinearProgress variant="determinate" value={progress} />
        <div className="new-user_main">
          {progress == 0 && <Avatar state={state} />}
          {progress == 25 && <Message state={state} setState={setState} status={status}/>}
          {progress == 50 && <Contact state={state} setState={setState} status={status}/> }
          {progress == 75 && <Education state={state} setState={setState} status={status}/>}
          {progress == 100 && <Family state={state} setState={setState} status={status}/>}
        </div>
        <div className="new-user_buttom">
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            {progress == 0?t('public.cancel'):t('public.recoil')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {progress != 100?t('public.next'):t('public.economize')}
          </Button>
        </div>
      </div>
    </>
  )
}

function Avatar({state}){
  const [anchorEl, setAnchorEl] = useState(false);
  const [avatar,setAvatar] = useState(state?.image)
  const handleClick = ()=>{
    setAnchorEl(true);
  }
  const handleUploadImage = async (e)=>{
    const image = e.target.files[0]
    const formData = new FormData();
    formData.append('file', image);
    const {data,code,msg} = await api.user.PostCommonFile(formData)
    if(code != 0)return 
    setAvatar(data.src)
    state.image = data.src
  }
  const handleClose = () => {
    setAnchorEl(false);
  };
  return (
    <>
    <div className="user-main_avatar">
      <label htmlFor="icon-button-file" className="avatar-box">
        {!state.image?<div className="avatar-box_text">
          Upload<br/> Profile image
        </div>:<img src={api.url + state.image} alt="" />}
      </label>
      <input accept="image/*"  id="icon-button-file" type="file" onChange={handleUploadImage}/>
      <Menu
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem className="browse-file" onClick={handleClose}>
          Browse File</MenuItem>
        <MenuItem onClick={handleClose}>
          <label htmlFor="icon-button-file">
            <span>Photo Library</span>
            <img src={photo_library} alt="" />
          </label>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <span>Take Photo</span>
          <img src={photo_camera} alt="" />
        </MenuItem>
        <MenuItem onClick={handleClose}> 
          <span>Browse</span>
          <img src={Dots} alt="" />
        </MenuItem>
      </Menu>
      
    </div>
    </>
  )
}

function Contact({state,setState,status}){
  const { t } = useTranslation()
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.contactInformation')}
        </div>
        <TextField
          label={`${t('new.residentialAddress')}*`}
          name='address'
          multiline
          variant="outlined"
          defaultValue={state.address}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.address || status?'':t('new.pleaseEnter')+ t('new.residentialAddress')}
        />
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.area')}*</InputLabel>
          <Select
            name='district'
            value={state.district}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'銅鑼灣'}>銅鑼灣</MenuItem>
            <MenuItem value={'尖沙咀'}>尖沙咀</MenuItem>
          </Select>
          {state.district || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.area')}</FormHelperText>}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.district')}*</InputLabel>
          <Select
            name='region'
            value={state.region}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'中西区'}>中西区</MenuItem>
            <MenuItem value={'湾仔区'}>湾仔区</MenuItem>
          </Select>
          {state.region || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.district')}</FormHelperText>}
        </FormControl>
        <TextField
          label={`${t('new.mobilePhoneNumber')}*`}
          name='phone_number'
          multiline
          variant="outlined"
          defaultValue={state.phone_number}
          disabled
        />
        <TextField
          label={`${t('new.electronicMail')}*`}
          name='email'
          multiline
          variant="outlined"
          defaultValue={state.email}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
        />
      </div>
    </>
  )
}

function Message({state,setState,status}){
  const { t } = useTranslation()
  const handleChangeDate = (date)=>{
    const dt = date?.$d
    console.log(dt)
    var y = dt.getFullYear();
	  var m = dt.getMonth() + 1;
	  m = m < 10 ? ('0' + m) : m;
	  var d = dt.getDate();
 	  d = d < 10 ? ('0' + d) : d;
    var h = dt.getHours()<10?('0' + dt.getHours()) : dt.getHours();
	  var minute = dt.getMinutes();
	  minute = minute < 10 ? ('0' + minute) : minute;
	  let time = y + '-' + m + '-' + d+' '+h+':'+minute;
    setState({ ...state, ['birth']: time })
  }
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.basicMessage')}
        </div>
        <TextField
          label={`${t('new.lastName')}*`}
          name='first_name'
          multiline
          variant="outlined"
          defaultValue={state.first_name}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.first_name || status?'':t('new.pleaseEnter')+t('new.lastName')}
        />
        <TextField
          label={`${t('new.theName')}*`}
          name='last_name'
          multiline
          variant="outlined"
          defaultValue={state.last_name}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.last_name || status?'':t('new.pleaseEnter')+t('new.theName')}
        />
        <TextField
          label={`${t('new.nickname')}*`}
          name='username'
          multiline
          variant="outlined"
          defaultValue={state.username}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.username || status?'':t('new.pleaseEnter')+t('new.nickname')}
        />
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">
            {t('new.sex')}*
          </InputLabel>
          <Select
            name='gender'
            value={state.gender}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'男'}>男</MenuItem>
            <MenuItem value={'女'}>女</MenuItem>
          </Select>
          {state.gender || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.sex')}</FormHelperText>}
        </FormControl>
        {/* <TextField
          label={`${t('new.dateOfBirth')}*`}
          name='birth'
          multiline
          variant="outlined"
          defaultValue={state.birth}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          helperText={state.birth || status?'':t('new.pleaseEnter')+t('new.dateOfBirth')}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={`${t('new.dateOfBirth')}*`}
            name='birth'
            defaultValue={dayjs(state.birth)}
            value={dayjs(state.birth)}
            onChange={handleChangeDate}
          />
          {state.birth || status?'':<FormHelperText>{t('new.pleaseEnter')+t('new.dateOfBirth')}</FormHelperText>}
        </LocalizationProvider>
      </div>
    </>
  )
}

function Education({state,setState,status}){
  const { t } = useTranslation()
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.educationAndIncome')}
        </div>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.educationalLevel')}*</InputLabel>
          <Select
            name='edu_level'
            value={state.edu_level}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'小學'}>小學</MenuItem>
            <MenuItem value={'初中'}>初中</MenuItem>
            <MenuItem value={'高中'}>高中</MenuItem>
            <MenuItem value={'專科'}>初中</MenuItem>
            <MenuItem value={'本科'}>本科</MenuItem>
            <MenuItem value={'研究生'}>研究生</MenuItem>
            <MenuItem value={'博士'}>博士</MenuItem>
          </Select>
          {state.edu_level || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.educationalLevel')}</FormHelperText>}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.on-the-jobStatus')}*</InputLabel>
          <Select
            name='employment_status'
            value={state.employment_status}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'在職'}>在職</MenuItem>
            <MenuItem value={'離職'}>離職</MenuItem>
          </Select>
          {state.employment_status || status?'':<FormHelperText>{t('new.pleaseSelect')+t('new.on-the-jobStatus')}</FormHelperText>}
        </FormControl>
      </div>
    </>
  )
}

function Family({state,setState,status}){
  const { t } = useTranslation()
  return (
    <>
      <div className="user-main_message">
        <div className="mian-message_title">
          {t('new.familyDetails')}
        </div>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.monthlyHouseholdIncome')}</InputLabel>
          <Select
            name='household_income'
            value={state.household_income}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'1000-3000'}>1000-3000</MenuItem>
            <MenuItem value={'3000-6000'}>3000-6000</MenuItem>
          </Select>
          {/* {state.household_income || status?'':<FormHelperText>請選擇家庭每月收入</FormHelperText>} */}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.maritalStatus')}</InputLabel>
          <Select
            name='marital_status'
            value={state.marital_status}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={1}>已婚</MenuItem>
            <MenuItem value={0}>未婚</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.numberOfFamilyMembers')}</InputLabel>
          <Select
            name='of_members'
            value={state.of_members}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'1-3'}>1-3</MenuItem>
            <MenuItem value={'3-6'}>3-6</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-label">{t('new.familyMembersAgeDistributionAnd')}</InputLabel>
          <Select
            name='age_distribution'
            value={state.age_distribution}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          >
            <MenuItem value={'10-20'}>10-20</MenuItem>
            <MenuItem value={'20-30'}>20-30</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={t('new.personalSkill')}
          name='personal_skills'
          multiline
          variant="outlined"
          defaultValue={state.personal_skills}
          onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
          // helperText={state.phone?'':'請輸入手機號碼'}
        />
      </div>
    </>
  )
}