import './index.less'
import {useHistory} from 'react-router-dom'
import IconLeft from '@/assets/images/navigation/left.svg'
export default function TopHead({title}){
  const histroy = useHistory()
  const handleBack = ()=>{
    window.history.go(-1)
  }
  return (
    <>
      <div className="top-head">
        <img src={IconLeft} alt="" onClick={handleBack} />
        <div className="title">
          {title}
        </div>
      </div>
    </>
  )
}