import './index.less'
import React from 'react'
import Dialog from '@mui/material/Dialog';
import Wc from '@/assets/images/login/OrangeCH.svg'
import Wc2 from '@/assets/images/login/group23.svg'
import Wc3 from '@/assets/images/login/group21.svg'
import { useDispatch,useSelector } from 'react-redux'
import commentIcon from '@/assets/images/icon/comment.svg'
import mailIcon from '@/assets/images/icon/mail.svg'
import callIcon from '@/assets/images/icon/call.svg'
function Contact({contactOpen,handleContactDetails}){
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const handleClose = ()=>{
    handleContactDetails(false)
  }
  return (
    <>
      <Dialog className="dialog-contact" onClose={handleClose} aria-labelledby="simple-dialog-title" open={contactOpen}>
        <img className="dialog-contact_logo" src={redusxUser.user_type == 1 && Wc || redusxUser.user_type == 2 && Wc2 || redusxUser.user_type == 3 && Wc3} alt="" />
        <div className="dialog-contact_title">
          揾小幫手幫幫您
        </div>
        <div className="dialog-contact_list">
          <div className="list-item">
            <div className="list-item_icon">
              <img src={commentIcon} alt="" />
            </div>
            <div className="list-item_info">
              <div className="info-text">
                WhatsApp
              </div>
              <div className="info-number">
                9562 2563
              </div>
            </div>
          </div>
          <div className="list-item">
            <div className="list-item_icon">
              <img src={callIcon} alt="" />
            </div>
            <div className="list-item_info">
              <div className="info-text">
                Call Our Office
              </div>
              <div className="info-number">
                2568 3698
              </div>
            </div>
          </div>
          <div className="list-item">
            <div className="list-item_icon">
              <img src={mailIcon} alt="" />
            </div>
            <div className="list-item_info">
              <div className="info-text">
                Email Us
              </div>
              <div className="info-number info-email">
                aikok@womencentre.org.hk
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
export default React.memo(Contact)