import api from '@/api'
import './index.less';
import { useEffect,useState } from 'react'
import {useHistory,useLocation  } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@/views/home/Card'
import qs from 'query-string'
export default function Collect (){
  const {search} = useLocation()
  const histroy = useHistory()
  const [total,setTotal] = useState(0)
  const [list,setList] = useState([])
  const [value, setValue] = useState(qs.parse(search).active?Number(qs.parse(search).active) : 0);
  useEffect(()=>{
    handleDetailList()
  },[value])
  const handleDetailList = ()=>{
    if(value == 0){
      handleEventList()
    }else{
      handleActiveList()
    }
  }
  const handleEventList = async ()=>{
    const {data,code,msg,total:count} = await api.user.GetUserCollectEvent()
    console.log(data,code,msg)
    setTotal(count)
    if(data.length == undefined)return setList([])
    setList(data)
  }
  const handleActiveList = async ()=>{
    const {data,code,msg,total:count} = await api.user.GetUserCollectActive()
    console.log(data,code,msg)
    setTotal(count)
    if(data.length == undefined)return setList([])
    setList(data)
  }
  const handleChange = (event, newValue) => {
    histroy.replace(`/usercollect/?active=${newValue}`)
    setValue(newValue);
  };
  return (
    <>
      <div className="collect">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="商品" value={0} />
          <Tab label="活动" value={1} />
        </Tabs>
        <div className="collect-main">
          {list.map((row)=><Card key={row.event_id} data={row}/>)}
        </div>
      </div>
    </>
  )
}