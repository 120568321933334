// my
import './index.less'
import {useEffect,useState,forwardRef,useRef } from 'react'
import {useHistory} from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useDispatch,useSelector } from 'react-redux';
import api from '@/api'
import Iconbutton from '@/assets/images/user/Iconbutton.svg'
import IconCode from '@/assets/images/user/code.svg'
import IconState from '@/assets/images/user/state.svg'
import IconTips from '@/assets/images/user/tips.svg'
import Iconlike from '@/assets/images/user/like.svg'
import IconAdd from '@/assets/images/user/add.svg' 
import IconRight from '@/assets/images/user/right.svg' 
import Hongkong from '@/assets/images/user/Hongkong.svg'
import UnitedKingdom from '@/assets/images/user/UnitedKingdom.svg'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import {getI18n,setI18n} from '@/utils/auth'
import TransferDialog from './components/Transfer'
import {UserAction,ShopAction,BalanceAction} from '@/redux/actions'
import {tipsReveal} from '@/redux/actions'
export default function My(){
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const histroy = useHistory()
  const [value, setValue] = useState(0);
  const [familyList,setFamilyList] = useState([])
  const [connectedList,setConnectedList] = useState([])
  const TransferDialogDiv = forwardRef(TransferDialog)
  const TransferDialogRef = useRef(null)
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  const redusxBalance = useSelector((state)=>{
    return state.UserRd.balance
  })
  const redusxSysBalance = useSelector((state)=>{
    return state.UserRd.sysBalance
  })
  const redusxSysAddress = useSelector((state)=>{
    return state.UserRd.address
  })
  useEffect(()=>{
    handleFamilyList()
    handleContactList()
    dispatch(BalanceAction())
  },[])
  const handleFamilyList = async()=>{
    const {data,code,msg} = await api.user.GetUserFamily({
      relation_type:1
    })
    if(data.length == undefined)return 
    setFamilyList(data)
  }
  const handleContactList = async()=>{
    const {data,code,msg} = await api.user.GetUserFamily({
      relation_type:2
    })
    if(data.length == undefined)return 
    setConnectedList(data)
  }
  const handleTransition = () => {
    histroy.push('/usertransition')
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleUser = ()=>{
    histroy.push('/usernew')
  }
  const handleFamily = (e)=>{
    histroy.push(`/usercontact?id=${e}`)
  }
  const handleFamilyEdit = (item,e)=>{
    console.log(item,e)
    histroy.push(`/usercontact?id=${e}&family_id=${item.family_id}`)
  }
  const handleTransfer = ()=>{
    TransferDialogRef.current.handleOpen()
  }
  const handleOmit = (e)=>{
    if(!e)return 
    var str = e;
    var last = 0;
    var all = str.length;
    var fisrt = str.substring(0,9);
    // 没有中文括号（
    if (str.lastIndexOf('（') == -1) {
        // 也没有英文括号(
        if (str.lastIndexOf('(') == -1) {
            last = all - 9;
        }else{
            // 有英文括号(，就从英文括号开始截取
            last = str.lastIndexOf('(');
        }
    }else{
        last = str.lastIndexOf('（');
    }
    // 如果长度大于13个字符才显示省略号
    if (all > 13) {
      return fisrt+" ... "+str.substring(last,all);
    }
  }

  const handleCopy = async (e)=>{
    try {
      dispatch(tipsReveal({message:'Successful replication',type:'success'}))
      await navigator.clipboard.writeText(e);
    } catch (err) { 
      dispatch(tipsReveal({message:'Failed to copy',type:'error'}))
    }
  }
  return (
    <div className='my'>
      <div className="my-avatar" onClick={handleUser}>
        <Avatar alt={redusxUser?.username} src={api.url + redusxUser?.image} />
      </div>
      <div className="my-address" onClick={()=>handleCopy(redusxSysAddress)}>
        {handleOmit(redusxSysAddress)}
      </div>
      <div className="my-account">
        <div className="my-account_name">
          {redusxUser?.username}
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="my-account_hm" onClick={handleTransition}>
          <img src={Iconbutton} alt="" />
          {redusxBalance} {t('my.hm')}  &nbsp;
          {redusxSysBalance} {t('my.hm')}
        </div>
      </div>
      <TransferDialogDiv ref={TransferDialogRef}/>
      <div className="my-icon">
        <div className="my-icon_item" onClick={()=>{histroy.push('/userqrcode')}}>
          <img src={IconCode} alt="" />
        </div>
        <div className="my-icon_item">
          <img src={IconState} alt="" onClick={()=>handleTransfer()}/>
        </div>
        <div className="my-icon_item">
          <img src={IconTips} alt="" onClick={()=>{histroy.push('/userexchange')}}/>
        </div>
        <div className="my-icon_item">
          <img src={Iconlike} alt="" onClick={()=>{histroy.push('/usercollect')}} />
        </div>
      </div>
      <div className="my-family">
        <div className="my-family_title">
          Family
        </div>
        <div className="my-family_main">
          {familyList.map(item=><Avatar key={item.family_id} alt={item.nickname} src={api.url + item.image} onClick={()=>handleFamilyEdit(item,1)}/>)}
          <div className='my-family_mian_add' onClick={()=>handleFamily(1)}>
            <img src={IconAdd} alt="" />
          </div>
        </div>
        
      </div>
      <div className="my-family">
        <div className="my-family_title">
          Connected with {connectedList.length} people
        </div>
        <div className="my-family_main">
          {connectedList.map(item=><Avatar key={item.family_id} alt={item.nickname} src={api.url + item.image} onClick={()=>handleFamilyEdit(item,2)}/>)}
          <div className='my-family_mian_add' onClick={()=>handleFamily(2)}>
            <img src={IconAdd} alt="" />
          </div>
        </div>
      </div>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="Personal details" />
        <Tab label="Settings" />
      </Tabs>
      <div className="my-content">
        {value == 0 &&<PersonalDetails user={redusxUser} />}
        {value == 1 &&<Settings />}
      </div>
    </div>
  )
}

function PersonalDetails({user}){
  const { t } = useTranslation()
  return (
    <>
      <div className="personal-details">
        <div className="personal-details_title">
          {t('new.basicMessage')}
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                Account ID no.
              </label>
              <div className="mian-card_info-value">
                {user?.uid}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.fullName')}:
              </label>
              <div className="mian-card_info-value">
                {user?.username} {user?.first_name} {user?.last_name}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.nickname')}:
              </label>
              <div className="mian-card_info-value">
                {user?.last_name}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.sex')}:
              </label>
              <div className="mian-card_info-value">
                {user?.gender}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.dateOfBirth')}:
              </label>
              <div className="mian-card_info-value">
                {user?.birth}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
        <div className="personal-details_text">
          {t('new.contactInformation')}
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.residentialAddress')}:
              </label>
              <div className="mian-card_info-value">
                {user?.district} {user?.region} {user?.address}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.mobilePhoneNumber')}:
              </label>
              <div className="mian-card_info-value">
                {user?.phone_number}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.electronicMail')}:
              </label>
              <div className="mian-card_info-value">
                {user?.email}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
        <div className="personal-details_text">
          {t('new.educationAndIncome')}
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.educationalLevel')}:
              </label>
              <div className="mian-card_info-value">
                {user?.edu_level}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.on-the-jobStatus')}:
              </label>
              <div className="mian-card_info-value">
                {user?.employment_status}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
        <div className="personal-details_text">
          {t('new.familyDetails')}
        </div>
        <div className="personal-details_main">
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('my.familyMonthlyIncome')}:
              </label>
              <div className="mian-card_info-value">
                {user?.household_income}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.maritalStatus')}:
              </label>
              <div className="mian-card_info-value">
                {user?.marital_status}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.numberOfFamilyMembers')}:
              </label>
              <div className="mian-card_info-value">
                {user?.of_members}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <label className="mian-card_info-label">
                {t('new.familyMembersAgeDistributionAnd')}:
              </label>
              <div className="mian-card_info-value">
                {user?.age_distribution}
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Settings(){
  const { t } = useTranslation()
  const [state,setState] = useState({
    phone:'',
  })
  const [value, setValue] = useState(getI18n()||'zh');
  const handleChange = (event)=>{
    setValue((event.target).value);
    setI18n((event.target).value)
    i18n.changeLanguage((event.target).value)
  }
  return (
    <div className="settings">
      <RadioGroup value={value} onChange={handleChange} row>
        <FormControlLabel value="zh" control={<Radio color="primary"/>} label={<><img src={Hongkong} alt="" />繁體中文</>} labelPlacement="start"/>
        <FormControlLabel value="en" control={<Radio color="primary"/>} label={<><img src={UnitedKingdom} alt="" />English</>} labelPlacement="start"/>
      </RadioGroup>
      <div className="settings-title">
        Security and Login
      </div>
      <div className="settings-form">
        <form action="">
          <TextField
            id="outlined-textarea"
            label="Mobile number"
            name='phone'
            multiline
            variant="outlined"
            defaultValue={state.phone}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            // error={state.phone?true:false}
            // helperText={state.phone?'':'請輸入手機號碼'}
          />
          <TextField
            id="outlined-textarea"
            label="Password"
            name='phone'
            multiline
            variant="outlined"
            defaultValue={state.phone}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            // error={state.phone?true:false}
            // helperText={state.phone?'':'請輸入手機號碼'}
          />
          <TextField
            id="outlined-textarea"
            label="re-enter password "
            name='phone'
            multiline
            variant="outlined"
            defaultValue={state.phone}
            onChange={(event)=>{setState({ ...state, [event.target.name]: event.target.value })}}
            // error={state.phone?true:false}
            // helperText={state.phone?'':'請輸入手機號碼'}
          />
        </form>
      </div>
      <div className="settings-title">
        Support
      </div>
      <div className="settings-main">
          <div className="mian-card">
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                Terms of Service
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                About
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                Help / FAQ
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
          <div className="mian-card">
            <div className="mian-card_info">
              <div className="mian-card_info-value">
                Contact us
              </div>
            </div>
            <div className="main-card_right">
              <img src={IconRight} alt="" />
            </div>
          </div>
        </div>
    </div>
  )
}
