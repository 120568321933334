import {useEffect} from 'react'
import Mrouter from '@/router'
import './App.less'
import {useHistory,Prompt} from 'react-router-dom'
import Navigation from '@/components/Navigation'
import AlertTips from '@/components/AlertTips'
import { createTheme,ThemeProvider } from '@mui/material/styles';
import '@/i18n/config'; // 引用配置文件
import { useDispatch,useSelector } from 'react-redux';
import {UserAction,ShopAction,BalanceAction} from '@/redux/actions'
import {getToken,removeToken} from '@/utils/auth'
var theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#F16526',
    },
  },
});
function App() {
  const dispatch = useDispatch()
  const histroy = useHistory()

  const redusxData = useSelector((state)=>{
    return state.NavigationRD.show
  })
  const redusxUser = useSelector((state)=>{
    return state.UserRd.user
  })
  useEffect(() => {
    if(getToken()){
      dispatch(UserAction())
      dispatch(BalanceAction())
      dispatch(ShopAction())
    }
  }, [getToken()]);
  useEffect(()=>{
    if(redusxUser.user_type == 2){
      theme.palette.primary.main = '#009700'
      document.documentElement.style.setProperty(
        "--primary-color",
        "#009700",
      );
      document.documentElement.style.setProperty(
        "--primary-background",
        "#E6FFE6"
      );
    }else if(redusxUser.user_type == 3){
      theme.palette.primary.main = '#A71D6B'
      document.documentElement.style.setProperty(
        "--primary-color",
        "#A71D6B",
      );
      document.documentElement.style.setProperty(
        "--primary-background",
        "#FBE9F3"
      );
    }else{
      theme.palette.primary.main = '#F16526'
      document.documentElement.style.setProperty(
        "--primary-color",
        "#F16526",
      );
      document.documentElement.style.setProperty(
        "--primary-background",
        "#FFEDE7"
      );
    }
  },[redusxUser])
  const handleleave = ()=>{
    return
    console.log(124)
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <AlertTips />
        <Mrouter>
          <Prompt
            when={false}
            message={() =>handleleave()}
          />
          {redusxData && <Navigation />}
        </Mrouter>
      </ThemeProvider>
    </>
  )
}

export default App
