import { combineReducers } from "redux"
import NavigationReducer from './NavigationReducer'
import ShoppingCartReducer from './ShoppingCartReducer'
import LoginReducer from "./LoginReducers"
import UserReducer from "./UserReducers"
import TipsReducers from "./TipsReducers"
export default combineReducers({
  NavigationRD:NavigationReducer,
  ShoppingCartRd:ShoppingCartReducer,
  LoginRd:LoginReducer,
  UserRd:UserReducer,
  TipsRd:TipsReducers
})