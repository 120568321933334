import {getToken,removeToken} from '@/utils/auth'
const LoginReducer = (prevState={
  token:getToken() || '',
},action)=>{
  var newStete = {...prevState}
  switch(action.type){
    case "LOGIN" :
      newStete.token = action.value
      return newStete 
    case "LOGOUT" :
      newStete.token = ''
      removeToken()
      return newStete 
    default:
      return newStete
  }
}
export default LoginReducer