import './index.less'
import React ,{useState,useImperativeHandle} from 'react'
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import api from '@/api'
import { useDispatch,useSelector } from 'react-redux';
import {ShopAction} from '@/redux/actions'
import subtract from '@/assets/images/shop/subtract.svg'
import add from '@/assets/images/shop/add.svg'
import stateLayer from '@/assets/images/shop/state-layer.svg'
const AppendDialog = (props,ref) => {
  const dispatch = useDispatch()
  const [appendOpen,setAppendOpen] = useState(false)
  const [amount,setAmount] = useState(1)
  const [detail,setDetail] = useState({
    banner:'',
    product_id:''
  })
  useImperativeHandle(ref,()=>({
    handleOpen:(e) => {
      setDetail(e)
      setAppendOpen(true)
    }
  }))
  const handleClose = ()=>{
    setAppendOpen(false)
  }
  const handleSubtract = ()=>{
    var num = amount-1
    setAmount(num)
  }
  const handleAdd = ()=>{
    var num = amount+1
    setAmount(num)
  }
  const handleSubmit = async()=>{
    const {data,code,msg} = await api.shop.PostCart({
      product_id:detail.product_id,
      num:amount,
    })
    if(code != 0)return 
    dispatch(ShopAction())
    setAmount(0)
    setAppendOpen(false)
    console.log(data,code,msg)
  }
  return (
    <Dialog className="append-dialog" onClose={handleClose} aria-labelledby="simple-dialog-title" open={appendOpen}>
      <img className="append-dialog_img" src={api.url + detail.banner} alt="" />
      <div className="append-dialog_container">
          <IconButton color="primary" disabled={amount<=1} onClick={()=>handleSubtract()}>
            <img src={subtract} alt="" />
          </IconButton>
          <div className="container_amount">
            {amount}
          </div>
          <IconButton color="primary" onClick={()=>handleAdd()}>
            <img src={add} alt="" />
          </IconButton>
      </div>
      <Button className="append-dialog_button" variant="contained" color="primary" onClick={()=>handleSubmit()}>
        <img src={stateLayer} alt="" />
        加入購物車
      </Button>
    </Dialog>
  )
}
export default AppendDialog