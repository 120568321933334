export default function DateFormat(date){
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
 
    const day = new Date(date);
    const dayName = days[day.getDay()];
    const monthName = months[day.getMonth()];
    const dayNumber = day.getDate();
    const year = day.getFullYear();
    // ${year}
    return `${dayName} ${monthName} ${dayNumber} `;
}