import './index.less'
import { useEffect,useState,forwardRef,useRef } from 'react';
import IconAdd from '@/assets/images/shear/add.svg'
import api from '@/api'
import ShearCard from '../Card'
import ServiceDialog from '../ServiceDialog'
import { useInView } from "react-cool-inview";
export default function Shear({handleDialogDetails,value}){
  const [list,setList] = useState([])
  const [total,setTotal] = useState(0)
  const ServiceDialogDiv = forwardRef(ServiceDialog)
  const ServiceDialogRef = useRef(null)
  const [formData,setFormData] = useState({
    is_share:value,
    limit:5,
    page:1,
  })
  useEffect(()=>{
    // setList([])
    setFormData({ ...formData, ['is_share']: value })
  },[value])
  useEffect(()=>{
    handleSecondhand()
  },[formData])
  const { observe } = useInView({
    rootMargin: "50px 0px",
    onEnter: ({ unobserve,observe }) => {
      if(total <= list.length)return unobserve()
      observe()
      var a = formData.page + 1
      setFormData({ ...formData, ['page']: a })
    },
  });
  const handleSecondhand = async ()=>{
    const {data,code,msg,total:count} = await api.shear.GetUserEvent(formData);
    setTotal(count)
    if(data.length == undefined){
      setList([])
      return 
    }
    setList([...list,...data])
  }
  const handleService = (e)=>{
    ServiceDialogRef.current.handleOpen(e)
  }
  return (
    <>
      <ServiceDialogDiv ref={ServiceDialogRef} />
      {list.length <= 0 && <div className="shear-box" onClick={()=>handleDialogDetails(true)}>
        <div className="shear-box_add">
          <img src={IconAdd} alt="" />
          <span>Add Event</span>
        </div>
      </div>}
      {list.map((item,index)=><div key={item.event_id + index} ref={index === list.length - 1 ? observe : null}><ShearCard item={item} type={value == 0?'request':'shear'} handleService={handleService}/></div>)}
    </>
  )
}

